import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import { useNavigate } from 'react-router';

export default function Labpage() {
       const navigate = useNavigate()
       const [value, setValue] = useState(0);
       const [formValues, setFormValues] = useState({
              facilityName: '',
              region: '',
              province: '',
              municipality: '',
              barangay: '',
              // longitude: '',
              // latitude: '',
              officialEmailAddress: '',
              officialContactNumber: '',
              typeOfLab: [],
              // ifFacilityBased: '', 
              // nonHospitalBased: '',
              categoryOfLab: '',
              // ifOthers: '',
              // ownershipOfLab: '',
              // ifGovernment: '', 
              // ifLocalGovernment: '',
              // ifNationalGovernment: '',
              specificRegion: '',
              specificProvince: '',
              specificCity: '',
              specificMunicipality: '',
              specificFacility: '',
              // FacilitiesWithMOA: '',
              operatingHours: '',
              operatingDays: '',
              // noOfPathologist: '',
              // noOfMedicalTechnologist: '',
              // noOfLaboratoryAide: '',
              // noOfAlliedHealthProf: '',
              // noOfNonMedicalEmployee: '',
              // noOfNonRegularProfessional: '',
              // noOfjobOrderProfessionals: '',
              // noOfProjectBasedProfessionals: '',
              // noOfDeployedStaff: '',
              // noOfStaff: '',
       });
       console.log(formValues, "::>>formValues")
       const handleInputChange = (event) => {
              console.log(event, "::>>event")
              const { name, value } = event.target;
              setFormValues({
                     ...formValues,
                     [name]: value,
              });
       };

       const handleSubmit = (event) => {
              event.preventDefault();
              console.log(formValues, "::>>formValues");
              localStorage.setItem("nonlabsData",JSON.stringify(formValues))
              navigate("/thankyou")
       };

       const handleCheckboxChange = (event) => {
              const { name, checked } = event.target;
              let updatedTypeOfLab = [...formValues.typeOfLab];
              if (checked) {
                     updatedTypeOfLab.push(name);
              } else {
                     updatedTypeOfLab = updatedTypeOfLab.filter((item) => item !== name);
              }
              setFormValues({
                     ...formValues,
                     typeOfLab: updatedTypeOfLab,
              });
       };
       return (
              <>
                     <CssBaseline />
                     <Container maxWidth="lg" className='p-0  fullheight labpagemain nonlabspage'>
                            <div className='labpage'>
                                   <AppBar position="static">
                                          <Toolbar>
                                                 <IconButton
                                                        size="large"
                                                        edge="start"
                                                        color="inherit"
                                                        aria-label="menu"
                                                        sx={{ mr: 2 }}
                                                 >
                                                        <MenuIcon />
                                                 </IconButton>
                                                 <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                                                        Laboratory
                                                 </Typography>
                                                 {/* <Button color="inherit">Login</Button> */}
                                          </Toolbar>
                                   </AppBar>

                                   <div className='signinpageupperpart'>
                                          <Grid container spacing={2}>

                                                 <Grid item xs={12} className='appnameright'>
                                                        <div className='apptitleholder w-100'>
                                                               <div className='headerapplogo'>
                                                               </div>
                                                               <p className='apptitle px-20px'>
                                                                      Multi Disease Diagnostic Network Optimization
                                                               </p>
                                                        </div>
                                                 </Grid>
                                          </Grid>

                                          <Grid container spacing={2}>
                                                 <Grid item xs={12}>
                                                        <p className='yellowlabelname mb-0 text-center'>
                                                               Please complete the form.
                                                        </p>
                                                 </Grid>
                                          </Grid>
                                   </div>

                                   <div className='labpagelaterpart'>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Facility Name</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="facilityName"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.facilityName}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Region</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="region"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.region}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Province</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="province"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.province}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Municipality</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="municipality"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.municipality}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Barangay</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="barangay"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.barangay}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Longitude</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="longitude"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.longitude}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Latitude</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="latitude"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.latitude}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Official email address</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="officialEmailAddress"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.officialEmailAddress}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Official contact number</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="officialContactNumber"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.officialContactNumber}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Type of Laboratory</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>

                                                        <FormGroup>
                                                               <FormControlLabel
                                                                      control={
                                                                             <Checkbox
                                                                                    name="Facility based"
                                                                                    checked={formValues.typeOfLab.includes("Facility based")}
                                                                                    onChange={handleCheckboxChange}
                                                                             />
                                                                      }
                                                                      label="Facility based"
                                                               />
                                                               <FormControlLabel
                                                                      control={
                                                                             <Checkbox
                                                                                    name="Free standing"
                                                                                    checked={formValues.typeOfLab.includes("Free standing")}
                                                                                    onChange={handleCheckboxChange}
                                                                             />
                                                                      }
                                                                      label="Free standing"
                                                               />
                                                        </FormGroup>
                                                 </Grid>
                                          </Grid>
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If Facility Based</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <RadioGroup
                                                               aria-labelledby="demo-radio-buttons-group-label"
                                                               name="ifFacilityBased" 
                                                               value={formValues.ifFacilityBased}
                                                               onChange={handleInputChange}
                                                        >
                                                               <FormControlLabel value="Hospital based" control={<Radio />} label="Hospital based" />
                                                               <FormControlLabel value="Non-hospital based" control={<Radio />} label="Non-hospital based" />

                                                        </RadioGroup>
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If Non-hospital based, specify
                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="nonHospitalBased"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.nonHospitalBased}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Category of Laboratory
                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="categoryOfLab"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.categoryOfLab}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If Others (Specify)

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="ifOthers"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.ifOthers}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Ownership of Laboratory

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="ownershipOfLab"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.ownershipOfLab}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If Government , then please specify
                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <RadioGroup
                                                               aria-labelledby="demo-radio-buttons-group-label"
                                                               name="ifGovernment" 
                                                               value={formValues.ifGovernment}
                                                               onChange={handleInputChange}>
                                                               <FormControlLabel value="DOH" control={<Radio />} label="DOH" />
                                                               <FormControlLabel value="Local Government Unit (LGU)" control={<Radio />} label="Local Government Unit (LGU)" />
                                                               <FormControlLabel value="National Government Agency (NGA)" control={<Radio />} label="National Government Agency (NGA)" />
                                                        </RadioGroup>
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If Local Government Unit (LGU)

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="ifLocalGovernment"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.ifLocalGovernment}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>If, National Government Agency (NGA). Specify

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="ifNationalGovernment"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.ifNationalGovernment}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Specific region/s being served:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="specificRegion"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.specificRegion}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Specific province/s being served:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="specificProvince"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.specificProvince}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Specify city/ies being served:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="specificCity"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.specificCity}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Specific municipality/ies being served:


                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="specificMunicipality"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.specificMunicipality}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Specific facility/ies being served:


                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="specificFacility"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.specificFacility}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Facilities with MOA being served:</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="FacilitiesWithMOA"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.FacilitiesWithMOA}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Operating Hours</p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="operatingHours"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.operatingHours}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Operating Days
                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="operatingDays"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.operatingDays}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of pathologist:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfPathologist"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfPathologist}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of medical technologist:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfMedicalTechnologist"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfMedicalTechnologist}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of laboratory aide:

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfLaboratoryAide"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfLaboratoryAide}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}
                                          {/* <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of other allied health professionals:


                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfAlliedHealthProf"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfAlliedHealthProf}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of non medical employees such as peer navigators


                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfNonMedicalEmployee"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfNonMedicalEmployee}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of are regular professionals
                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfNonRegularProfessional"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfNonRegularProfessional}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>

                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of  job order professionals

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfjobOrderProfessionals"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfjobOrderProfessionals}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of project based professionals

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfProjectBasedProfessionals"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfProjectBasedProfessionals}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of deployed staff assigned in the facility

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfDeployedStaff"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfDeployedStaff}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid>
                                          <Grid container spacing={2} className='formrow'>
                                                 <Grid item xs={12}>
                                                        <p className='m-0 text-left formlabelbold'>Total number of staff

                                                        </p>
                                                 </Grid>
                                                 <Grid item xs={12} className='pt-0px'>
                                                        <TextField id="outlined-basic"
                                                               name="noOfStaff"
                                                               label=""
                                                               variant="outlined"
                                                               placeholder=''
                                                               value={formValues.noOfStaff}
                                                               onChange={handleInputChange} />
                                                 </Grid>
                                          </Grid> */}

                                          <p className='w-100'>
                                                 <a href='#' className='greenbtn' onClick={handleSubmit}>Submit</a>
                                          </p>
                                   </div>
                            </div>
                     </Container>
                     {/* <BottomNavigation
                            className='btmnavbar'
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                   setValue(newValue);
                            }}
                     >
                            <BottomNavigationAction label="Facilities Info" icon={<DomainAddIcon />} />
                            <BottomNavigationAction label="Diagnostic Info" icon={<MonitorHeartIcon />} />
                            <BottomNavigationAction label="Samples Info" icon={<BloodtypeIcon />} />
                     </BottomNavigation> */}
              </>
       );
}