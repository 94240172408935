export const apiUrl = "https://f65e-103-252-7-235.ngrok.io";
export const baseUrl = "https://f65e-103-252-7-235.ngrok.io";

// export const getStriderOtp =  `${baseUrl}/getotp`
export const getStriderOtp =  "https://mdnoservice.duredemos.com/getotp"
export const verifyStriderOtp =  "https://mdnoservice.duredemos.com/verifyotp"
export const registerStrider =  "https://mdnoservice.duredemos.com/strd/register"
export const registerCourier =  "https://mdnoservice.duredemos.com/courier/register"
export const getNonLabList =  "https://mdnoservice.duredemos.com/nlab/getnlabs"
export const addSample =  "https://mdnoservice.duredemos.com/sample/addsample"
export const getSampleList =  "https://mdnoservice.duredemos.com/sample/getsamples"
export const uploadDocuments =  "https://mdnoservice.duredemos.com/strd/uploaddocs"
export const getAlluploadedDocuments =  "https://mdnoservice.duredemos.com/strd/getprofile"