import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './../../components/Sidebar/sidebar';
import { useLocation, useNavigate } from 'react-router';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function Totalsample() {
  const [age, setAge] = React.useState('10');
  const navigate = useNavigate();
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleNavigate = () => {
    navigate("/riderlogin")
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight totalsampledeliveriesmain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className='sidebarbtn'
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >

                <Sidebar></Sidebar>
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Total Sample Info
              </Typography>
              <Button color="inherit" className='homeicon' onClick={handleNavigate}></Button>
            </Toolbar>
          </AppBar>


          <div className='riderloginpagepart bg-white bgimgnone'>
            <p className='bg-whitegrey tripdetailstext mb-0 text-right'>
              {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className='selectsamplefield'
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={10}>Select</MenuItem>
          <MenuItem value={20}>STRider</MenuItem>
          <MenuItem value={30}>Direct</MenuItem>
        </Select> */}
            </p>
            <Grid container spacing={2} className='formrow w-100 pt-10px pb-15px yellowgradient'>
              <Grid item xs={4} className='p-0 pt-10px'>
                <p className='triptime tragicfont'>60</p>
                <p className='tripdistance samplenameblack'>Today</p>
              </Grid>
              <Grid item xs={4} className='p-0 pt-10px'>
                <p className='triptime tragicfont'>500</p>
                <p className='tripdistance samplenameblack'>Month</p>
              </Grid>
              <Grid item xs={4} className='p-0 pt-10px'>
                <p className='triptime tragicfont'>4000</p>
                <p className='tripdistance samplenameblack'>Year</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow w-100'>


              <Grid item xs={12} className='p-0'>
                <p className='bg-whitegrey tripdetailstext mb-0'>Today's Samples</p>
                <Grid container spacing={2} className='formrow w-100 pt-10px pb-15px bg-white'>
                  <Grid item xs={4} className='p-0 pt-10px'>
                    <p className='triptime tragicfont'>26</p>
                    <p className='tripdistance samplenameblack'>HIV</p>
                  </Grid>
                  <Grid item xs={4} className='p-0 pt-10px'>
                    <p className='triptime tragicfont'>51</p>
                    <p className='tripdistance samplenameblack'>TB</p>
                  </Grid>
                  <Grid item xs={4} className='p-0 pt-10px'>
                    <p className='triptime tragicfont'>32</p>
                    <p className='tripdistance samplenameblack'>HPV</p>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className='formrow w-100 todaysampledivmainrow'>
                <p class="bg-whitegrey tripdetailstext mb-0 recentDeliveries">Recent Deliveries</p>
                  <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                    <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain'>
                      <Grid item xs={8} className='p-10px pt-10px'>
                        <p className='tripdistance fs-14px text-left samplename'>CAMARIN 175 HEALTH CENTER</p>
                        <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HPV, TB</p>
                      </Grid>
                      <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                        <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                    <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain yellow'>
                      <Grid item xs={8} className='p-10px pt-10px'>
                        <p className='tripdistance fs-14px text-left samplename'>PARKLAND HEALTH CENTER</p>
                        <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HIV, HPV, TB</p>
                      </Grid>
                      <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                        <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                    <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain'>
                      <Grid item xs={8} className='p-10px pt-10px'>
                        <p className='tripdistance fs-14px text-left samplename'>KALIGAYAHAN HEALTH CENTER</p>
                        <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HPV, TB</p>
                      </Grid>
                      <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                        <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>



                {/* <Grid container spacing={2} className='formrow w-100 pt-0px'>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left'>Service Type</p>
       </Grid>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-right'>Parcel</p>
       </Grid>
       </Grid> */}
                {/* <Grid container spacing={2} className='formrow w-100 pt-0px'>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left'>Trip Type</p>
       </Grid>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-right'>Normal</p>
       </Grid>
       </Grid> */}




              </Grid>

            </Grid>







          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}