import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../../components/Sidebar/sidebar';
import * as URL from "../../services/url";
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import PageLoader from '../../components/PageLoader/PageLoader';
import Switch from '@mui/material/Switch';
import GoogleMapsComp from './GoogleMapsComp';
import DummyData from '../../DummyData.json';
import Checkbox from '@mui/material/Checkbox';


export default function Riderdeliveries() {
  const navigate = useNavigate();
  const [allSampleData, setAllSampleData] = useState("")
  const [loading, setLoading] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [originPoint, setOriginPoint] = useState("")
  const [destinationPoint, setDestinationPoint] = useState("")
  const [waypointsArray, setwaypointsArray] = useState([])
  const [endTrip, setEndTrip] = useState(false)
  useEffect(() => {
    // getSampleCollectionData()
  }, [])

  useEffect(() => {
    let locArray = [];
    const waypoints = DummyData[0].map((store) => {
      locArray.push({ lat: store.location_latitude, lng: store.location_longitude });
      setwaypointsArray(locArray);
      return {
        location: { lat: store.location_latitude, lng: store.location_longitude },
      };
    });
    console.log(waypoints, "::>>waypoints")


    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;
    setOriginPoint(origin);
    setDestinationPoint(destination);
  }, [DummyData]);

  localStorage.setItem("receiptData",JSON.stringify(DummyData[0]));
  const handleSampleCollections = () => {
    let data = {
      "id": 77,
      "non_genx_id": 25503,
      "FacilityName": "Manila SHC",
      "GenexName": "LINGUNAN HEALTH CENTER",
      "CatchmentArea": "Yes",
      "LatitudeCatchment1": 41.07767137637747,
      "LongitudeCatchment2": 28.798677564035916,
      "LongitudeCatchment3": 14.485655154308054,
      "LongitudeCatchment4": 121.047048563052,
      "LongitudeCatchment5": 14.662563015724508,
      "LongitudeCatchment6": 120.94570356694628,
      "LongitudeCatchment7": 14.864346654363658,
      "LongitudeCatchment8": 121.05504738303756,
      "LongitudeCatchment9": 14.528517701231078,
      "LongitudeCatchment10": 121.06906025345056,
    }


    console.log(data, "::>handleSampleCollections")
    navigate("/riderreceipt", { state: DummyData[0] })
  }

  const handleAddSample = () => {
    navigate("/addsampledetails")
  }

  const getSampleCollectionData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(URL.getSampleList);
      setLoading(false)
      if (response.status == 200) {
        console.log(response, "::>>getSampleCollectionData")
        setAllSampleData(response.data.data)
      }
    } catch (error) {
      setLoading(false)
      toast.error(error)
      console.error('Error:', error);
    }
  }

  let waypointsTrimmed = waypointsArray.slice(1, -1);
  let remainingWayPts = waypointsTrimmed.map(point => `${point.lat},${point.lng}`).join('|');

  console.log(remainingWayPts, "::>remainingWayPts")
  let mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${originPoint.lat + ',' + originPoint.lng}&waypoints=${remainingWayPts}&destination=${destinationPoint.lat + ',' + destinationPoint.lng}`

  const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
    setEndTrip(true)
  }

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight riderdeliverypagemain'>
        <div className='createprofilepage bg-green'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar></Sidebar>
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Sample Collection
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>

          <div className='viewchanger'>
            <span>List View</span>
            <Switch
              onChange={(e) => setToggleState(!toggleState)}
              label="" 
              color="default"
              checked={toggleState}
            />
            <span>Map View</span>
          </div>

          {!toggleState && <div>
            <div className='pt-30px'>
              <Grid container spacing={2} className='formrow riderdeliveryform'>
                {DummyData[0].length > 0 ? DummyData[0].slice(1, -1).map((data, index) => {
                  return (
                    <Grid item xs={12} md={6} className='pl-0px' key={index}>
                      <div className='deliverydiv'>
                        <div className='deliverydivLeftWrapper'>
                          <p className='facilityname'>
                            {data?.location_name}
                          </p>
                          <p className='parcelname'>
                            <span>HIV: {data?.hiv_sample}</span>
                            <span>TB: {data?.tb_sample}</span>
                            <span>HPV: {data?.hpv_sample}</span>
                          </p>
                        </div>
                        {endTrip && <Checkbox color='default' />}
                      </div>
                    </Grid>
                  )
                }) : <Grid item xs={12} md={6} className='pl-0px'>

                  <div className='deliverydiv'>
                    <p className='facilityname'>
                      No samples available
                    </p>
                  </div>
                </Grid>}

              </Grid>
            </div>

            <div className='addsamplebtnrowmain'>
              <Grid container spacing={2} className='formrow'>
                <Grid item xs={12} className='pl-0px'>
                  <button onClick={handleAddSample} className='addsamplebtn'>Add Sample</button>
                  {/* <button className='starttripbtn' onClick={handleSampleCollections} >Start Trip</button> */}
                  {!endTrip ? <button className='starttripbtn' onClick={() => openInNewTab(mapUrl)} >Start</button> :
                    <button className='starttripbtn' onClick={handleSampleCollections}>End</button>}
                </Grid>
              </Grid>
            </div>
          </div>}

          {toggleState &&
            // <div className='googleMpasWrapper'>
            <div className='pt-30px'>
              <Grid container spacing={2} className='formrow riderdeliveryform'>
                <Grid item xs={12} className='pl-0px'>
                  <div className='googlemapdiv'>
                    <GoogleMapsComp
                      setToggleState={setToggleState}
                      toggleState={toggleState}
                      setEndTrip={setEndTrip}
                      endTrip={endTrip}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          }

        </div>
      </Container>
    </React.Fragment>
  );
}