import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as URL from "../../services/url";
import axios from 'axios';
import { getErrorString } from '../../constants/utils';
import PageLoader from '../../components/PageLoader/PageLoader';
import Sidebar from '../../components/Sidebar/sidebar';
import { useEffect } from 'react';

export default function Uploadbikedoc() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [regCertificateFileName, setRegCertificateFileName] = useState("");
  const [insuranceFileName, setInsuranceFileName] = useState("");
  const [ownerCertificateFileName, setOwnerCertificateFileName] = useState("");
  const [pucFileName, setPucFileName] = useState("");
  const [defaultLatitude, setDefaultLatitude] = useState("")
  const [defaultLongitude, setDefaultLongitude] = useState("")

  const [registrationCertificateURL, setRegistrationCertificateURL] = useState("")
  const [insurancePolicyURL, setInsurancePolicyURL] = useState("")
  const [ownerCertificateURL, setOwnerCertificateURL] = useState("")
  const [pucCertificateURL, setPucCertificateURL] = useState("")

  let userRole = localStorage.getItem("userRole");
  let localStorageStriderFormData = localStorage.getItem("striderFormData");
  let parsedData = JSON.parse(localStorageStriderFormData);

  let personalDocsURL = localStorage.getItem("docsURLObj");
  let parsedURLData = JSON.parse(personalDocsURL);

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setDefaultLatitude(latitude);
    setDefaultLongitude(longitude)
    console.log(`Latitude: ${latitude}, Longitude: ${longitude} register`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const [regCertificateFileType, setRegCertificateFileType] = useState("")
  const handleUploadBikeDocuments = async (e, param) => {
    const file = e.target.files[0];
    if (file && file.size > 5000000) {
      toast.error("Document size should be less than 5 MB");
      return;
    }
    let fileUrl;
    if (file instanceof Blob) {

      if (param === "RegistrationCertificate") {
        // setRegCertificateFileName(file.name);
        let fileType = file.type.split('/').pop();
        setRegCertificateFileName(`registration-certificate.${fileType}`);
      } else if (param === "InsurancePolicy") {
        // setInsuranceFileName(file.name);
        let fileType = file.type.split('/').pop();
        setInsuranceFileName(`insurance-policy.${fileType}`);
      } else if (param === "OwnerCertificate") {
        setOwnerCertificateFileName(file.name);
      } else if (param === "PUC") {
        // setPucFileName(file.name);
        let fileType = file.type.split('/').pop();
        setPucFileName(`puc.${fileType}`);
      }

      const reader = new FileReader();
      const fileUrlPromise = new Promise((resolve, reject) => {
        reader.onload = (e) => {
          const url = e.target.result;
          fileUrl = url;
          resolve(url);
        };
        reader.onerror = (error) => {
          reader.abort();
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      await fileUrlPromise;
      // param == "RegistrationCertificate" && setRegCertificateFileName(e.target.files[0] && e.target.files[0].name);
      // param == "InsurancePolicy" && setInsuranceFileName(e.target.files[0] && e.target.files[0].name);
      // param == "OwnerCertificate" && setOwnerCertificateFileName(e.target.files[0] && e.target.files[0].name);
      // param == "PUC" && setPucFileName(e.target.files[0] && e.target.files[0].name);
      // const reader = new FileReader();
      // reader.onload = (e) => {
      //   const url = e.target.result;
      //   param == "RegistrationCertificate" && setRegistrationCertificateURL(url);
      //   param == "InsurancePolicy" && setInsurancePolicyURL(url)
      //   param == "OwnerCertificate" && setOwnerCertificateURL(url)
      //   param == "PUC" && setPucCertificateURL(url)
      // };
      // reader.readAsDataURL(file);
      console.log(fileUrl, "::>fileUrl")
    }
    else {
      toast.error("Invalid file format");
    }
    let docType = "";
    if (param == "RegistrationCertificate") {
      docType = "regcert ";
    } else if (param == "InsurancePolicy") {
      docType = "insurance ";
    } else if (param == "OwnerCertificate") {
      docType = "ownercert ";
    } else if (param == "PUC") {
      docType = "puc";
    }

    const body = {
      phonenumber: localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"),
      doctype: docType,
      docbody: fileUrl,
    };

    setLoading(true);
    try {
      const response = await axios.post(URL.uploadDocuments, body);
      setLoading(false);
      if (response.status === 200) {
        // navigate("/thankyou");
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setLoading(false)
    } finally {
      setLoading(false)
    }

  }


  const handleSubmitBikeDocuments = async () => {
    if (regCertificateFileName && insuranceFileName) {
      navigate("/thankyou")
    }
    else {
      toast.error("Please upload all the documents")
    }
  }


  useEffect(() => {
    userRole && getAllBikeDocuments()
  }, [])

  const handleCheckExtension = (docUrl, param) => {
    let extension;
    if (docUrl && docUrl.includes("image/jpeg")) {
      extension = "jpeg";
      param == "regCert" && setRegCertificateFileName(`registration-certificate.${extension}`)
      param == "insurance" && setInsuranceFileName(`insurance-policy.${extension}`)
      param == "puc" && setPucFileName(`puc.${extension}`)
    } else if (docUrl && docUrl.includes("image/png")) {
      extension = "png";
      param == "regCert" && setRegCertificateFileName(`registration-certificate.${extension}`)
      param == "insurance" && setInsuranceFileName(`insurance-policy.${extension}`)
      param == "puc" && setPucFileName(`puc.${extension}`)
    } else if (docUrl && docUrl.includes("application/pdf")) {
      extension = "pdf";
      param == "regCert" && setRegCertificateFileName(`registration-certificate.${extension}`)
      param == "insurance" && setInsuranceFileName(`insurance-policy.${extension}`)
      param == "puc" && setPucFileName(`puc.${extension}`)
    } else {
      extension = "unknown";
    }
  }

  const getAllBikeDocuments = async () => {
    const body = {
      phonenumber: localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"),
      type: "documents",
      // docbody: fileUrl,
    };

    setLoading(true);
    try {
      const response = await axios.post(URL.getAlluploadedDocuments, body);
      setLoading(false);
      if (response.status === 200) {
        console.log(response, ":>>responsee")
        handleCheckExtension(response.data.data.regcert, "regCert")
        handleCheckExtension(response.data.data.insurance, "insurance")
        handleCheckExtension(response.data.data.puc, "puc")
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }



  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight uploadbikedocpagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                {/* <MenuIcon /> */}
                {userRole && <Sidebar></Sidebar>}
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Upload Vehicle Document
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>
                    Multi Disease Diagnostic Network Optimization
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please upload documents
                </p>
              </Grid>
            </Grid>


          </div>

          <div className='createprofilepagelaterpart'>
            <div className='uploaddocformholder'>
              <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Registration Certificate
                    <span className="fileNamelabel">{regCertificateFileName ? `File : ${regCertificateFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadBikeDocuments(e, "RegistrationCertificate")}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Insurance Policy
                    <span className="fileNamelabel">{insuranceFileName ? `File : ${insuranceFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadBikeDocuments(e, "InsurancePolicy")}
                    />
                  </div>
                </Grid>
              </Grid>
              {/* <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Owner Certificate
                    <span className="fileNamelabel">{ownerCertificateFileName ? `File : ${ownerCertificateFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadBikeDocuments(e, "OwnerCertificate")}
                    />
                  </div>
                </Grid>
              </Grid> */}
              {/* <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>PUC
                    <span className="fileNamelabel">{pucFileName ? `File : ${pucFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadBikeDocuments(e, "PUC")}
                    />
                  </div>
                </Grid>
              </Grid> */}

              <p className='w-100 mt-40px'>
                <button onClick={handleSubmitBikeDocuments} className='greenbtn'>Next</button>
              </p>
            </div>
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}