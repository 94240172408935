import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import axios from 'axios';
import * as URL from "../../services/url";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from '../../constants/utils';
import PageLoader from '../../components/PageLoader/PageLoader';


export default function Otppage() {
  const navigate = useNavigate();
  const location = useLocation()
  const locName = location.state;
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  let finalOTP;
  let phoneNumber = localStorage.getItem("phoneNumber");
  console.log(phoneNumber, "::>>phoneNumber")
  // const handleVerifyOtp = async () => {
  //   const formData = new FormData();
  //   formData.append("countrycode", localStorage.getItem("countryCode"))
  //   formData.append("phonenumber", localStorage.getItem("phoneNumber"))
  //   formData.append("otp", finalOTP)
  //   formData.append("type", locName)
  //   formData.append("deviceid", localStorage.getItem("UUID"))
  //   try {
  //     const response = await axios.post(URL.verifyStriderOtp, formData);
  //     if (response.status == 200) {
  //       console.log(response, "::>RESP")
  //       localStorage.setItem("userRole", response.data.userRole)
  //       if (response.data.registrationstatus === "approved") {
  //         if (response.data.userRole === "strider") {
  //           navigate("/riderlogin")
  //         }
  //         if (response.data.userRole === "nonlabs") {
  //           navigate("/labpage")
  //         }
  //       }
  //       if (response.data.registrationstatus === "pending") {
  //         toast.error("Approval is pending")
  //         return;
  //       }
  //       if (response.data.registrationstatus === "rejected") {
  //         toast.error("Application is rejected")
  //         return;
  //       }
  //       if (response.data.registrationstatus === "profilePending") {
  //         navigate("/createProfile")
  //       }


  //       toast.success(response.message)
  //     }
  //   } catch (error) {
  //     toast.error(error)
  //     console.error('Error:', error);
  //   }
  // }

  const handleVerifyOtp = async () => {
    const formData = new FormData();
    if (!finalOTP || finalOTP.toString().length <= 3) {
      toast.error("Please enter valid otp");
      return;
    }
    formData.append("countrycode", localStorage.getItem("countryCode"))
    formData.append("phonenumber", localStorage.getItem("phoneNumber"))
    formData.append("otp", finalOTP)
    formData.append("type", locName)
    formData.append("deviceid", localStorage.getItem("UUID"))
    setLoading(true)
    try {
      const response = await axios.post(URL.verifyStriderOtp, formData);
      setLoading(false)
      if (response.status == 200) {
        console.log(response, "::>RESP")
        localStorage.setItem("userRole", response.data.userRole)
        if (response.data.userRole === "nonlabs") {
          navigate("/labpage")
        }
        if (response.data.registrationstatus === "approved") {
          if (response.data.userRole === "strider") {
            navigate("/riderlogin")
          }
        } else if (response.data.registrationstatus === "pending") {
          toast.error("Approval is pending")
          navigate("/");
        } else if (response.data.registrationstatus === "rejected") {
          toast.error("Application is rejected")
          navigate("/");
        } else if (response.data.userRole === "strider" && response.data.registrationstatus === "profilePending") {
          navigate("/createProfile")
        }
        toast.success(response.data.message)
      }
    } catch (error) {
      // Hide loader here...
      setLoading(false)
      toast.error("An error occurred")
      console.error('Error:', error);
    } finally {
      setLoading(false)
    }
  }


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  finalOTP = otp.join('');
  console.log(finalOTP, "::>>finalOTP")
  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight otppage'>
        <div className='signinpage'>
          <div className='signinpageupperpart'>
            <Grid container spacing={2} className='px-20px'>
              {/* <Grid item xs={8}>
        <div className='userpassimg'>

        </div>
       </Grid> */}
              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle'>Multi Disease Diagnostic Network Optimization</p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='labelname mb-0 text-center'>Enter Access Code</p>
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={2}>

              </Grid>
              <div className="userInput">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="otp-input"
                      type="text"
                      name="otp"
                      id="ist"
                      key={index}
                      value={data}
                      maxlength="1"
                      placeholder="-"
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
              {/* <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits1(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits2(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits3(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
              <Grid item xs={2}>
                <TextField onChange={(e) => setOtpDigits4(e.target.value)} id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid> */}
              <Grid item xs={2}>

              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
        <p className='labelname mb-0 text-center'>No account yet?</p>
        <p className='labelname mb-0 text-center mt-0 text-underline'>Register</p>
       </Grid> */}
          </div>

          <div className='signinpagelaterpart'>
            <p className='w-100'>
              <button onClick={handleVerifyOtp} className='greenbtn'>Submit</button>
            </p>
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}