import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export default function Addbikedetails() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight addbikedetailspagemain'>
       <div className='createprofilepage'>
       <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
            Add Vehicle Details
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
       <div className='signinpageupperpart'>
       <Grid container spacing={2}>
      
       <Grid item xs={12} className='appnameright'>
       <div className='apptitleholder w-100'>
        <div className='headerapplogo'>
        </div>
        <p className='apptitle px-20px'>
Multi Disease Diagnostic Network Optimization 
       </p>
       </div>
       </Grid>
       </Grid>
       
       <Grid container spacing={2}>
       <Grid item xs={12}>
        <p className='yellowlabelname mb-0 text-center'>
            Please complete the form.
        </p>
       </Grid>
       </Grid>
     
      
       </div>

       <div className='createprofilepagelaterpart'>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Brand</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Model</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Number Plate</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Color</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       
      
     
        <p className='w-100'>
            <a href='#' className='greenbtn'>Next</a>
        </p>
       </div>
      
      
       
       </div>
      </Container>
    </React.Fragment>
  );
}