import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import * as URL from "../../services/url";
import axios from 'axios';

export default function Courierpage() {
  const [courierDetails, setCourierDetails] = useState({
    sampleDetails: "",
    source: "",
    destination: "",
    noOfSample: "",
    typeOfSample: ""
  })

  const handleInputChange = (event, field) => {
    const updatedDetails = { ...courierDetails, [field]: event.target.value };
    setCourierDetails(updatedDetails);
  };

  const handleRegisterCourier = async () => {    
    let reqPayload = {
      cname: "",
      address: "",
      latitude: "",
      longitude: ""
    }
    console.log(reqPayload, "::>> reqPayloadyOTP")
    try {
      const response = await axios.post(URL.verifyStriderOtp, reqPayload);
      if (response.status == 200) {
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error)
      console.error('Error:', error);
    }
  }

  console.log(courierDetails, "::::>>courierDetails")
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight courierpagemain'>
        <div className='courierpage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Courier
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>
                    Multi Disease Diagnostic Network Optimization
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please complete the form.
                </p>
              </Grid>
            </Grid>
          </div>

          <div className='courierpagelaterpart'>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Sample Details</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder=''
                  value={courierDetails.sampleDetails}
                  onChange={(e) => handleInputChange(e, 'sampleDetails')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Source</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder=''
                  value={courierDetails.source}
                  onChange={(e) => handleInputChange(e, 'source')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Destination</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder=''
                  value={courierDetails.destination}
                  onChange={(e) => handleInputChange(e, 'destination')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>No. of Sample</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder=''
                  value={courierDetails.noOfSample}
                  onChange={(e) => handleInputChange(e, 'noOfSample')}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Type of Sample</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  placeholder=''
                  value={courierDetails.typeOfSample}
                  onChange={(e) => handleInputChange(e, 'typeOfSample')}
                />
              </Grid>
            </Grid>

            <p className='w-100 '>
              <a href='#' className='greenbtn mb-20px'>Submit</a>
            </p>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}