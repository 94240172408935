import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Formio } from 'formiojs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as URL from "../../services/url";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getErrorString } from '../../constants/utils';
import PageLoader from '../../components/PageLoader/PageLoader';

export default function Createprofile() {
  const [currentPage, setCurrentPage] = useState(0);
  const [striderFormData, setStriderFormData] = useState("")
  const [defaultLatitude, setDefaultLatitude] = useState("")
  const [defaultLongitude, setDefaultLongitude] = useState("")
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    Formio.createForm(document.getElementById('formio'), {
      display: "wizard",
      components: [
        {
          "title": "Page 1",
          "breadcrumb": "none",
          "breadcrumbClickable": true,
          "buttonSettings": {
            "previous": false,
            "cancel": false,
            "next": true
          },
          "navigateOnEnter": false,
          "saveOnEnter": false,
          "scrollToTop": false,
          "collapsible": false,
          "hideLabel": true,
          "key": "page1",
          "type": "panel",
          "label": "Page 1",
          "components": [
            {
              "label": "First Name",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "First name",
              "key": "firstname",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Last Name",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Last name",
              "key": "lastname",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Address",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Address",
              "key": "address",
              "type": "textfield",
              "input": true
            }
          ],
          "input": false,
          "tableView": false
        },
        {
          "title": "Page 2",
          "breadcrumb": "none",
          "breadcrumbClickable": true,
          "buttonSettings": {
            "previous": true,
            "cancel": false,
            "next": true
          },
          "navigateOnEnter": false,
          "saveOnEnter": false,
          "scrollToTop": false,
          "collapsible": false,
          "hideLabel": true,
          "key": "page2",
          "type": "panel",
          "label": "Page 2",
          "components": [
            {
              "label": "Bank Name",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Bank name",
              "key": "bankname",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Account Holder Name",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Account holder name",
              "key": "accholdername",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Account Number",
              "applyMaskOn": "change",
              "mask": false,
              "tableView": false,
              "delimiter": false,
              "requireDecimal": false,
              "inputFormat": "plain",
              "truncateMultipleSpaces": false,
              "validate": {
                "required": true
              },
              "errorLabel": "Account number",
              "key": "accountnumber",
              "type": "number",
              "input": true
            },
            {
              "label": "Bank Code",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Bank code",
              "key": "ifsc",
              "type": "textfield",
              "input": true
            }
          ],
          "input": false,
          "tableView": false
        },
        {
          "title": "Page 3",
          "breadcrumb": "none",
          "breadcrumbClickable": true,
          "buttonSettings": {
            "previous": true,
            "cancel": false,
            "next": true
          },
          "navigateOnEnter": false,
          "saveOnEnter": false,
          "scrollToTop": false,
          "collapsible": false,
          "hideLabel": true,
          "key": "page3",
          "type": "panel",
          "label": "Page 3",
          "components": [
            {
              "label": "Brand",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Brand",
              "key": "brand",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Model",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Model",
              "key": "model",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Number Plate",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Number plate",
              "key": "numplate",
              "type": "textfield",
              "input": true
            },
            {
              "label": "Color",
              "applyMaskOn": "change",
              "tableView": true,
              "validate": {
                "required": true
              },
              "errorLabel": "Color",
              "key": "color",
              "type": "textfield",
              "input": true
            }
          ],
          "input": false,
          "tableView": false
        }
      ]
    }).then(function (wizard) {
      wizard.on('nextPage', function (data) {
        setCurrentPage(data?.page)
      });
      wizard.on('prevPage', function (data2) {
        setCurrentPage(data2?.page)
      });
      wizard.on('gotoNextPage', function () {
        wizard.nextPage();
      });
      wizard.on('gotoPreviousPage', function () {
        wizard.prevPage();
      });
      wizard.on('submit', function (submission) {
        console.log(submission, "::>>submission")
        setStriderFormData(submission?.data)
        handleSubmitStriderProfile(submission?.data)
        // localStorage.setItem("striderFormData",JSON.stringify(submission?.data))
        // navigate("/uploaddoc")
      })
      // wizard.on('wizardSave', function () {
      //   wizard.submit().then(function (submission) {
      //     console.log(submission,"::>>submission")
      //     wizard.onChange();
      //     wizard.nextPage();
      //   });
      // })
    })

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setDefaultLatitude(latitude);
      setDefaultLongitude(longitude)
      console.log(`Latitude: ${latitude}, Longitude: ${longitude} register`);
    }

    function error() {
      console.log("Unable to retrieve your location");
    }

  }, [])
  console.log(striderFormData, "::>>striderFormData")

  let breadcrumbHeading = [
    "Create Profile",
    "Add Bank Details",
    "Add Vehicle Details",
  ]
  console.log(defaultLatitude, defaultLongitude, "::>Live Loc");

  const handleSubmitStriderProfile = async (data) => {
    console.log(defaultLatitude,defaultLongitude,"::>>defaultttttt")
    let formData = new FormData();
    formData.append('fname', data?.firstname);
    formData.append('lname', data?.lastname);
    formData.append('latitude', defaultLatitude ? defaultLatitude : 20.433033);
    formData.append('longitude', defaultLongitude ? defaultLongitude : 121.95889);
    // formData.append('birthcert', parsedURLData?.birthCertificateURL);
    // formData.append('drivelic', parsedURLData?.drivingLicenseURL);
    formData.append('address', data?.address);
    formData.append('bankname', data?.bankname);
    formData.append('accholdername', data?.accholdername);
    formData.append('accnumber', data?.accountnumber);
    formData.append('ifsc', data?.ifsc);
    formData.append('brand', data?.brand);
    formData.append('model', data?.model);
    formData.append('numberplate', data?.numplate);
    formData.append('color', data?.color);
    // formData.append('passport', parsedURLData?.passportURL);
    // formData.append('regcert', registrationCertificateURL);
    // formData.append('insurance', insurancePolicyURL);
    // formData.append('ownercert', ownerCertificateURL);
    // formData.append('puc', pucCertificateURL);

    formData.append('phonenumber', localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"));
    console.log(formData, ":>>>formData")
    setLoading(true)
    try {
      const response = await axios.post(URL.registerStrider, formData);
      setLoading(false)
      if (response.status == 200) {
        // navigate("/thankyou");
        navigate("/uploaddoc")
        console.log(response, "::>>response")
        toast.success(response.message)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      toast.error(getErrorString(error))
    } finally {
      setLoading(false)
    }

  }

  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              {/* <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton> */}
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                {breadcrumbHeading[currentPage]}
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>

                    Multi Disease Diagnostic Network Optimization </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please complete the form.
                </p>
              </Grid>
            </Grid>


          </div>

          <div className='createprofilepagelaterpart'>
            <div id="formio"></div>
            {/* <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>First Name</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Last Name</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Address</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid> */}



            {/* <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Password</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid> */}



            {/* <p className='w-100'>
              <a href='#' className='greenbtn'>Register</a>
            </p> */}
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}