import logo from './logo.svg';
import './App.css';
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from "./views/Home/home";
import SplashScreen from './views/SplashScreen/splashscreen';
import Signup from './views/Signup/signup';
import Signin from './views/Signin/signin';
import Otppage from './views/Otp/otppage';
import Createprofile from './views/CreateProfile/createprofile';
import Uploaddoc from './views/UploadDocuments/uploaddoc';
import Addbankdetails from './views/BankDetails/addbankdetails';
import Addbikedetails from './views/BikeDetails/addbikedetails';
import Uploadbikedoc from './views/UploadDocuments/uploadbikedoc';
import Homemenupage from './views/Home/homemenupage';
import Ridersignin from './views/RiderInfo/ridersignin';
import Riderlogin from './views/RiderInfo/riderlogin';
import Riderdeliveries from './views/RiderInfo/riderdeliveries';
import Ridertrip from './views/RiderInfo/ridertrip';
import Riderreceipt from './views/RiderInfo/riderreceipt';
import Thankyou from './views/ThankyouPage/thankyou';
import Totaldeliveries from './views/DeliveriesInfo/totaldeliveries';
import Sidebar from './components/Sidebar/sidebar';
import Labpage from './views/Lab/labpage';
import Labpagesecond from './views/Lab/labpagesecond';
import Labpagethird from './views/Lab/labpagethird';
import Totalsample from './views/DeliveriesInfo/totalsampledeliveries';
import Courierpage from './views/CourierInfo/courierpage';
import Addsampledetails from './views/AddSampleDetails/addsampledetails';
import { useEffect } from 'react';
import { ProtectedRoutes } from './constants/utils';
import Riderreceiptsecond from './views/RiderInfo/riderreceiptsecond';
import Feedback from './views/Feedback/feedback';
import ReportChallenges from './views/Feedback/reportchallenges';
import RiderProfilePage from './views/RiderInfo/RiderProfile';

function App() {
  let phoneNumber = localStorage.getItem("phoneNumber");
  console.log(phoneNumber, "::>>phoneNumber")
  return (
    <div className="App">
      <Routes>
        {/* <Route path='/' element={<Home/>} />
      <Route path='/home' element={<Home/>} />
      <Route path='/splash' element={<SplashScreen/>} /> */}
        <Route path='/' element={<Signup />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signin' element={<Signin />} />
        <Route path='/otp' element={<Otppage />} />

        <Route path='/createprofile' element={<Createprofile />} />
        <Route path='/uploaddoc' element={<Uploaddoc />} />
        <Route path='/addbankdetails' element={<Addbankdetails />} />
        <Route path='/addbikedetails' element={<Addbikedetails />} />
        <Route path='/uploadbikedoc' element={<Uploadbikedoc />} />
        <Route path='/homemenupage' element={<Homemenupage />} />
        <Route path='/ridersignin' element={<Ridersignin />} />
        <Route path='/riderlogin' element={<Riderlogin />} />
        <Route path='/riderdeliveries' element={<Riderdeliveries />} />
        <Route path='/ridertrip' element={<Ridertrip />} />
        {/* <Route path='/riderreceipt' element={<Riderreceipt />} /> */}
        <Route path='/riderreceipt' element={<Riderreceiptsecond />} />
        <Route path='/thankyou' element={<Thankyou />} />
        <Route path='/totaldeliveries' element={<Totaldeliveries />} />
        <Route path='/sidebar' element={<Sidebar />} />
        <Route path='/labpage' element={<Labpage />} />
        <Route path='/labpagesecond' element={<Labpagesecond />} />
        <Route path='/labpagethird' element={<Labpagethird />} />
        <Route path='/totalsampledeliveries' element={<Totalsample />} />
        <Route path='/courierpage' element={<Courierpage />} />
        <Route path='/addsampledetails' element={<Addsampledetails />} />
        <Route path='/feedback' element={<Feedback />} />
        <Route path='/reportchallenges' element={<ReportChallenges />} />
        <Route path='/riderprofile' element={<RiderProfilePage />} />

        {/* =================Protected Routes=============================== */}
        {/* <Route path='/createprofile' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Createprofile />} />
        <Route path='/uploaddoc' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Uploaddoc />} />
        <Route path='/addbankdetails' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Addbankdetails />} />
        <Route path='/addbikedetails' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Addbikedetails />} />
        <Route path='/uploadbikedoc' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Uploadbikedoc />} />
        <Route path='/homemenupage' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Homemenupage />} />
        <Route path='/ridersignin' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Ridersignin />} />
        <Route path='/riderlogin' element={phoneNumber == null ? <Navigate to="/" replace={true} /> : <Riderlogin />} />
        <Route path='/riderdeliveries' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Riderdeliveries />} />
        <Route path='/ridertrip' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Ridertrip />} />
        <Route path='/riderreceipt' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Riderreceipt />} />
        <Route path='/thankyou' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Thankyou />} />
        <Route path='/totaldeliveries' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Totaldeliveries />} />
        <Route path='/sidebar' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Sidebar />} />
        <Route path='/labpage' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Labpage />} />
        <Route path='/labpagesecond' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Labpagesecond />} />
        <Route path='/labpagethird' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Labpagethird />} />
        <Route path='/totalsampledeliveries' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Totalsample />} />
        <Route path='/courierpage' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Courierpage />} />
        <Route path='/addsampledetails' element={!phoneNumber ? <Navigate to="/" replace={true} /> : <Addsampledetails />} />         */}
      </Routes>
    </div>
  );
}

export default App;
