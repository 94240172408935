import React, { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../../components/Sidebar/sidebar';
import { useLocation } from "react-router-dom";
import InititalRiderMap from './InititalRiderMap';

import L from 'leaflet';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
// import BikeIc from "./assets/images/delivery.png"
import BikeSvgIc from "../../assets/images/bike-new-ic.svg"
import CatchmentSvgIc from "../../assets/images/catchment-ic.svg"
import GenexSvgIc from "../../assets/images/genex-ic.svg"
import dataJson from "../../output.json"
import multipleLatLong from "../../multipleLatLong.json"

import 'leaflet-defaulticon-compatibility';


export default function Ridertrip() {
  const location = useLocation()
  const tripData = location.state;
  const navigate = useNavigate();
  const [toggleMap, setToggleMap] = useState(false)
  const [loading, setLoading] = useState(true);
  console.log(tripData, "::>>Data routed")
  const handleNavigate = () => {
    setToggleMap(!toggleMap)
    // navigate("/riderreceipt")
  }
  
  const handleEndTrip =()=>{
    navigate("/riderreceipt",{state:tripData})
  }

  console.log(loading,"::>>loading")
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight ridertrippagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar></Sidebar>
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Trip Details
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>


          {/* <div className='riderloginpagepart'> */}
          {!toggleMap &&
            <div>
              <InititalRiderMap setLoading={setLoading} loading={loading}/>
              <div className='riderloginpagepartNew'>
                <Grid container spacing={2} className='formrow w-100'>
                  <Grid item xs={12} className=''>
                    {/* <p className='text-center'>
            <div className='ridericon'></div>
        </p> */}
                    <p className='text-center'>
                      {/* <div className='mapmarkerimg'></div> */}
                    </p>
                  </Grid>
                  <Grid item xs={12} className='riderstartbtnholder tripdetailsdiv bottomzero'>
                    <p className='triptime'>59 min</p>
                    <p className='tripdistance'>38 km</p>
                    <p className='tripdistance text-left mt-10px fs-14px pt-20px'>
                      <div className='bluecircle'></div>{"Manila SHC"}</p>
                    <p className='tripdistance text-left mt-10px fs-14px'>
                      <div className='greencircle'></div>{"Taguig City Health Office"}</p>
                    {loading == false && <p class="w-100 pt-10px"><button onClick={handleNavigate} class="greenbtn">Navigate</button></p>}
                  </Grid>

                </Grid>
              </div>
            </div>
          }

          {toggleMap &&
            <div>
              <div className="tripStartSection">
                <MapContainer center={[0, 0]} zoom={2}>
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="..."
                  />
                  <LeafletRoutingMachine />
                </MapContainer>
                <button onClick={handleEndTrip} class="greenbtn endRideButton">End Trip</button>
              </div>
            </div>}
        </div>
      </Container>
    </React.Fragment>
  );
}

function LeafletRoutingMachine() {
  const map = useMap();
  const [waypoints, setWaypoints] = useState([]);
  const [routingControl, setRoutingControl] = useState(null);
  // const [deliveryData, setDeliveryData] = useState(dataJson.filtered_catchment_data);
  const [deliveryData, setDeliveryData] = useState(multipleLatLong.filtered_catchment_data);
  // console.log(deliveryData, "::deliveryData")
  // console.log(multipleLatLong, "::multipleLatLong")
  useEffect(() => {
    if (navigator.geolocation) {
      const id = navigator.geolocation.watchPosition(
        success,
        error,
        { enableHighAccuracy: true }
      );

      return () => {
        navigator.geolocation.clearWatch(id);
      };
    } else {
      console.log('Geolocation not supported');
    }
  }, []);

  function success(position) {
    // const { latitude, longitude } = position.coords;
    // const currentPosition = L.latLng(latitude, longitude);
    // map.setView(currentPosition, 80);

    // ----------------Use this for testing purpose---------------
    // setWaypoints([
    //     currentPosition,
    //     L.latLng(19.193728986647663, 72.96736579752958),
    //     L.latLng(19.21014604762048, 72.97221688197041),
    // ]);

    // setWaypoints([
    //   L.latLng(deliveryData[0]?.stRider_location_lat, deliveryData[0]?.stRider_location_lon),
    //   L.latLng(deliveryData[0]?.Latitude, deliveryData[0]?.Longitude),
    //   L.latLng(deliveryData[0]?.Latitude_genx, deliveryData[0]?.Longitude_genx),
    // ]);
   
    setWaypoints([
      L.latLng(deliveryData[0]?.LatitudeCatchment1, deliveryData[0]?.LongitudeCatchment1),
      L.latLng(deliveryData[0]?.LatitudeCatchment2, deliveryData[0]?.LongitudeCatchment2),
      L.latLng(deliveryData[0]?.LatitudeCatchment3, deliveryData[0]?.LongitudeCatchment3),
      L.latLng(deliveryData[0]?.LatitudeCatchment4, deliveryData[0]?.LongitudeCatchment4),
    ]);
  }

  function error() {
    console.log('Unable to retrieve your location');
  }

  useEffect(() => {
    if (waypoints.length > 0) {
      const control = L.Routing.control({
        waypoints: waypoints,
        // serviceUrl: 'http://my-osrm/route/v1',
        lineOptions: {
          styles: [
            {
              color: 'red',
              weight: 3,
            },
          ],
        },
      }).addTo(map);

      setRoutingControl(control);

      const customIcons = [
        BikeSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        GenexSvgIc,
      ];

      waypoints.forEach((waypoint, index) => {
        L.marker(waypoint, {
          icon: L.icon({
            iconUrl: customIcons[index],
            iconSize: [30, 30],
            // iconAnchor: [12, 41],
            // popupAnchor: [1, -34],
          }),
        }).addTo(map).bindPopup(`Waypoint ${index + 1}`);
      });

      // return () => {
      //     map.removeControl(control);
      //     waypoints.forEach(waypoint => map.removeLayer(waypoint));
      // };
    }
  }, [waypoints, map]);

  return null;
}