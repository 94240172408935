import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

export default function Thankyou() {
  const navigate = useNavigate();
  let userRole = localStorage.getItem("userRole");
  const handleThankyouPage = () => {
    if (userRole == "nonlabs") {
      navigate("/addsampledetails")
    } else {
      navigate("/riderlogin")
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight'>
        <div className='createprofilepage bg-green vert-center'>
          {/* <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
          Samples Collection
          </Typography> */}
          {/* <Button color="inherit">Login</Button> */}
          {/* </Toolbar>
      </AppBar> */}


          <div className=''>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12} className='pl-0px'>
                <div className='thankyouWrapper'>
                  <p className='facilityname'>
                    Registration Successful
                  </p>
                  <p className='parcelname'>
                    Thank you for registering with {userRole == "nonlabs" ? "Facility" : "STRider"} .
                  </p>
                  <p className='parcelname'>
                    Your registration is pending for approval.
                  </p>
                  <p className='mb-0'>
                    <button onClick={handleThankyouPage} className='okbtn'>Okay</button>
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}