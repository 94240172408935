import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Formio } from 'formiojs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../../components/Sidebar/sidebar';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';



export default function ReportChallenges() {
  const [currentPage, setCurrentPage] = useState(0);
  const [striderFormData, setStriderFormData] = useState("")
  const navigate = useNavigate();
  
  useEffect(() => {
    

  }, [])

  

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
          <Toolbar>
          <IconButton
          className='sidebarbtn'
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0 }}
          >
          
            <Sidebar></Sidebar>
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
          Report Challenges
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>

                    Multi Disease Diagnostic Network Optimization </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please complete the form.
                </p>
              </Grid>
            </Grid>


          </div>

          <div className='createprofilepagelaterpart'>
          
           <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Name</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Trip Details</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <TextField id="outlined-basic" label="" variant="outlined" multiline
                minRows={2}
          maxRows={4} placeholder='' />
              </Grid>
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Challenges Faced</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
              <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel value="female" control={<Radio />} label="Unavailabilty of parcel" />
        <FormControlLabel value="male" control={<Radio />} label="Breakdown" />
        <FormControlLabel value="other" control={<Radio />} label="On Leave" />
      </RadioGroup>
              </Grid>
            </Grid> 



      



           <p className='w-100'>
              <a href='#' className='greenbtn'>Submit</a>
            </p> 
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}