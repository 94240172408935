import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router';
import Sidebar from '../../components/Sidebar/sidebar';

export default function Riderreceiptsecond() {
  const location = useLocation();
  const navigate = useNavigate();
  const tripEndedData = location.state;
  const handleClick = () => {
    navigate("/totalsampledeliveries")
  }
  let finalReceiptData = JSON.parse(localStorage.getItem("receiptData"))
  const origin = finalReceiptData && finalReceiptData[0];
  const destination = finalReceiptData && finalReceiptData[finalReceiptData.length - 1];
  const remainingPoints = finalReceiptData && finalReceiptData.slice(1, -1);
  console.log(remainingPoints, "::>>remainingPoints")
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight riderreceiptpagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar></Sidebar>
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Trip Receipt
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>


          <div className='riderloginpagepart bg-white bgimgnone receiptpageholder'>
            <Grid container spacing={2} className='formrow w-100'>
              <Grid item xs={12} className='bg-whitegrey'>
                <p className='tripdistance text-left mt-0px fs-14px'>
                  <div className='bluecircle'></div>{origin?.location_name}</p>
                <p className='tripdistance text-left mt-10px fs-14px'>
                  <div className='greencircle'></div>{destination?.location_name}</p>

              </Grid>

              <Grid item xs={12} className='p-0'>
                <p className='bg-whitegrey tripdetailstext yellowgradient'>Summary</p>
                <p className='text-center tripdistance'>Delivery Made Successfully.</p>
                <Grid container spacing={2} className='formrow w-100 pt-20px pb-20px'>
                  <Grid item xs={6} className='p-0 pt-10px'>
                    <p className='triptime'>55 min</p>
                    <p className='tripdistance'>Time</p>
                  </Grid>
                  <Grid item xs={6} className='p-0 pt-10px'>
                    <p className='triptime'>15 km</p>
                    <p className='tripdistance'>Distance</p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} className='formrow w-100 todaysampledivmainrow'>
                <p className='bg-whitegrey tripdetailstext recentDeliveries'>Sample Collection Details</p>
                {remainingPoints.length > 0 && remainingPoints.map((data, index) => {
                  return (

                    <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                      <Grid container spacing={2} className={`formrow w-100 pt-0px sampledivmain ${data?.status.includes("Pending") ? "yellow" : ""}`}>
                        <Grid item xs={8} className='p-10px pt-10px'>
                          <p className='tripdistance fs-14px text-left samplename'>{data?.location_name}</p>
                          <p className='tripdistance fs-14px text-left samplenamegreen'><span className='sampleHeading'>Samples :</span> <br /> {data?.hiv_sample ? `HIV : ${data?.hiv_sample}` : 'HIV : 0'}, {data?.hpv_sample ? `HPV : ${data?.hpv_sample}` : 'HPV : 0'}, {data?.tb_sample ? `TB : ${data?.tb_sample}` : 'TB : 0'}</p>
                        </Grid>
                        <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                          <p className='tripdistance fs-14px text-right samplestatus'>{data?.status}</p>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
                {/* <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                  <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain yellow'>
                    <Grid item xs={8} className='p-10px pt-10px'>
                      <p className='tripdistance fs-14px text-left samplename'>CAMARIN 175 HEALTH CENTER</p>
                      <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: TB</p>
                    </Grid>
                    <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                      <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={4} className='p-0 todaysamplesinglediv'>
                  <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain'>
                    <Grid item xs={8} className='p-10px pt-10px'>
                      <p className='tripdistance fs-14px text-left samplename'>CALOOCAN CITY NORTH MEDICAL CENTER</p>
                      <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HPV</p>
                    </Grid>
                    <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
                      <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              {/* <Grid item xs={12} className='p-0'>
                <p className='bg-whitegrey tripdetailstext'>Sample Collection Details</p>
                {remainingPoints && remainingPoints.map((data, index) => {
                  return (
                    <Grid container spacing={2} className='formrow w-100 pt-0px samplefacilitynamediv'>
                      <Grid item xs={6} className='p-10px pt-10px vert-center'>
                        <p className='tripdistance fs-14px text-left samplefacilityname'>{data?.location_name} || BRIXTON HEALTH CENTER</p>
                      </Grid>
                      <Grid item xs={6} className='p-10px pt-10px vert-center'>
                        <p class="tripdistance fs-14px text-right samplenamegreen mt-0">
                          {data?.hiv_sample ? `HIV : ${data?.hiv_sample}` : ""} 
                          {data?.hpv_sample ? `HPV : ${data?.hpv_sample}` : ""}
                          {data?.tb_sample ? `TB : ${data?.tb_sample}` : ""}</p>
                      </Grid>
                    </Grid>
                  )
                })
                }

                <Grid container justifyContent="center"
                  spacing={2} className='formrow w-100 pt-20px pb-20px'>
                  <button className='greenbtn' onClick={handleClick}>Go to Dashboard</button>
                </Grid>
              </Grid> */}
              <Grid container justifyContent="center"
                spacing={2} className='formrow w-100 pt-20px pb-20px'>
                <button className='greenbtn' onClick={handleClick}>Go to Dashboard</button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}