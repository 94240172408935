import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from './../../components/Sidebar/sidebar';

export default function Totaldeliveries() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight totaldeliveriespagemain'>
       <div className='createprofilepage'>
       <AppBar position="static">
        <Toolbar>
          <IconButton
          className='sidebarbtn'
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 0 }}
          >
          
            <Sidebar></Sidebar>
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
           Total Deliveries
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
   

       <div className='riderloginpagepart bg-white bgimgnone'>
       <p className='bg-whitegrey tripdetailstext mb-0'>Thu, 28 Sep’23</p>
       <Grid container spacing={2} className='formrow w-100 pt-10px pb-15px yellowgradient'>
       <Grid item xs={4} className='p-0 pt-10px'>
       <p className='triptime tragicfont'>15</p>
     <p className='tripdistance samplenameblack'>Today</p>
       </Grid>
       <Grid item xs={4} className='p-0 pt-10px'>
       <p className='triptime tragicfont'>150</p>
     <p className='tripdistance samplenameblack'>Month</p>
       </Grid>
       <Grid item xs={4} className='p-0 pt-10px'>
       <p className='triptime tragicfont'>2000</p>
     <p className='tripdistance samplenameblack'>Year</p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow w-100'>
     
     
       <Grid item xs={12} className='p-0'>
       <p className='bg-whitegrey tripdetailstext'>Recent Samples</p>
     
       <Grid container spacing={2} className='formrow w-100 pt-0px todaysampledivmainrow'>
       <Grid item xs={12} md={4} className='todaysamplesinglediv'>
       <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain'>
       <Grid item xs={8} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left samplename'>BIGNAY MAIN HEALTH STATION</p>
       <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HIV</p>
       </Grid>
       <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
       <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
       </Grid>
       </Grid>
       </Grid>
       <Grid item xs={12} md={4} className='todaysamplesinglediv'>
       <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain yellow'>
       <Grid item xs={8} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left samplename'>LAWANG BATO HEALTH CENTER</p>
       <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HIV,TB</p>
       </Grid>
       <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
       <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
       </Grid>
       </Grid>
       </Grid>
       <Grid item xs={12} md={4} className='todaysamplesinglediv'>
       <Grid container spacing={2} className='formrow w-100 pt-0px sampledivmain'>
       <Grid item xs={8} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left samplename'>CANUMAY EAST LIBIS HEALTH STATION</p>
       <p className='tripdistance fs-14px text-left samplenamegreen'>Sample: HIV</p>
       </Grid>
       <Grid item xs={4} className='p-10px pt-10px samplestatusdiv'>
       <p className='tripdistance fs-14px text-right samplestatus'>Completed</p>
       </Grid>
       </Grid>
       </Grid>
     
     
     
       </Grid>
       {/* <Grid container spacing={2} className='formrow w-100 pt-0px'>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left'>Service Type</p>
       </Grid>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-right'>Parcel</p>
       </Grid>
       </Grid> */}
       {/* <Grid container spacing={2} className='formrow w-100 pt-0px'>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-left'>Trip Type</p>
       </Grid>
       <Grid item xs={6} className='p-10px pt-10px'>
       <p className='tripdistance fs-14px text-right'>Normal</p>
       </Grid>
       </Grid> */}
   
   
    
   
       </Grid>
      
       </Grid>
      
      
      
       
      
     
     
       </div>
      
      
       
       </div>
      </Container>
    </React.Fragment>
  );
}