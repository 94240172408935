import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../src/assets/css/custom.css';
// import '../src/assets/css/customdesktop.css';
import '../src/assets/css/customwebapp.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import 'react-toastify/dist/ReactToastify.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderReactDom = () => {
  root.render(
    <React.StrictMode>
      <Router>
        <App />
        <ToastContainer
          position={"bottom-right"}
          autoClose={3000}
          hideProgressBar={true}
          pauseOnHover={true}
        />
      </Router>
    </React.StrictMode>
  );
};

if (window.cordova) {
  let UUID;
  document.addEventListener('deviceready', () => {
    renderReactDom();
    UUID = window.cordova ? window.device.uuid : "";
    localStorage.setItem("UUID", UUID);
  }, false);
} else {
  let desktopUUID = uuidv4();
  localStorage.setItem("UUID", desktopUUID);
  renderReactDom();
}

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
