import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import DummyData from '../../DummyData.json';
import BikeSvgIc from "../../assets/images/bike-new-ic.svg";
import CatchmentSvgIc from "../../assets/images/catchment-ic.svg";
import GenexSvgIc from "../../assets/images/genex-ic.svg";
import { DirectionsRenderer } from "@react-google-maps/api";

const mapStyles = {
    width: "100%",
    height: "100%",
};

function MapsNewComp(props) {
    const {setToggleState, toggleState, setEndTrip, endTrip} = props;
    const [stores] = useState(DummyData[0]); 
    console.log(toggleState,"::>>Map view")
    const customIcons = [
        BikeSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        CatchmentSvgIc,
        GenexSvgIc,
    ];

    const [activeMarker, setActiveMarker] = useState(null);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({});
    const [originPoint, setOriginPoint] = useState("")
    const [destinationPoint, setDestinationPoint] = useState("")
    const [waypointsArray, setwaypointsArray] = useState([])
    const [directions, setDirections] = useState(null);

    useEffect(() => {
                // const waypoints = DummyData[0].map((store) => ({
                //     location: { lat: store.location_latitude, lng: store.location_longitude },
                // }));
        
                let locArray = [];
                const waypoints = DummyData[0].map((store) => {
                    locArray.push({ lat: store.location_latitude, lng: store.location_longitude }); 
                    setwaypointsArray(locArray);
                    return {
                        location: { lat: store.location_latitude, lng: store.location_longitude },
                    };
                }); 
                console.log(waypoints,"::>>waypoints")
        
        
                const origin = waypoints.shift().location;
                const destination = waypoints.pop().location;
                setOriginPoint(origin);
                setDestinationPoint(destination);

                // const directionsService = new window.google.maps.DirectionsService();              
                // directionsService.route(
                //     {
                //         origin: origin,
                //         destination: destination,
                //         waypoints: waypoints,
                //         travelMode: window.google.maps.TravelMode.DRIVING,
                //     },
                //     (result, status) => {
                //         console.log(result, status,"::>>result status")
                //         if (status === window.google.maps.DirectionsStatus.OK) {
                //             setDirections(result);
                //         } else {
                //             console.error("Error fetching directions: ", result);
                //         }
                //     }
                // );

                const directionsService = new window.google.maps.DirectionsService();

directionsService.route(
    {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
    },
    (result, status) => {
        console.log(result, status, "::>>result status");
        if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
        } else {
            console.error("Error fetching directions: ", result);
            if (status === window.google.maps.DirectionsStatus.ZERO_RESULTS) {
                console.warn("No route found");
            } else if (status === window.google.maps.DirectionsStatus.NOT_FOUND) {
                console.warn("Some locations were not found");
            } else {
                console.error("Unknown error occurred");
            }
        }
    }
);

            }, [DummyData]);
            
    const onMarkerClick = (props, marker) => {
        setSelectedPlace(props);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    console.log(destinationPoint,"::>>destinationPoint")
    let waypointsTrimmed = waypointsArray.slice(1, -1);
    let remainingWayPts = waypointsTrimmed.map(point => `${point.lat},${point.lng}`).join('|');

    const displayMarkers = () => {
        return stores.map((store, index) => {
            const markerIcon = {
                url: customIcons[index % customIcons.length],
                scaledSize: new window.google.maps.Size(40, 40),
                anchor: new window.google.maps.Point(17, 46),
            };
            return (
                <Marker
                    key={index}
                    position={{
                        lat: store.location_latitude,
                        lng: store.location_longitude,
                    }}
                    icon={markerIcon}
                    onClick={(props, marker) =>
                        onMarkerClick(
                            {
                                name: store.location_name,
                                latitude: store.location_latitude,
                                longitude: store.location_longitude,
                            },
                            marker
                        )
                    }
                />
            );
        });
    };

    let mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${originPoint.lat + ',' + originPoint.lng}&waypoints=${remainingWayPts}&destination=${destinationPoint.lat + ',' + destinationPoint.lng}`
    console.log(originPoint,"::>>originPoint")
    function openInNewTab(url) {
        setToggleState(!toggleState)
        setEndTrip(true)
        window.open(url, '_blank').focus();
      }
    return (
        <div>
            <Map
                google={props.google}
                zoom={14} 
                style={mapStyles}
                initialCenter={{ lat: 14.76847558, lng: 121.0370721 }}
            >
                {displayMarkers()}

                {/* <!--------- Comment this code to hide route ----------!> */}
                {directions && (
                    <DirectionsRenderer
                        options={{
                            directions: directions,
                            suppressMarkers: true
                        }}
                    />
                )}
                <InfoWindow
                    marker={activeMarker}
                    visible={showInfoWindow}
                    onClose={() => setShowInfoWindow(false)}
                >
                    <div>
                        <h4>{selectedPlace.name}</h4>
                        <p>Latitude: {selectedPlace.latitude}</p>
                        <p>Longitude: {selectedPlace.longitude}</p>
                    </div>
                </InfoWindow>
            </Map>
            <div className="startTripWrapper">
                {!endTrip ? <button className="startButton" onClick={()=>openInNewTab(mapUrl)}>Start</button> :
                <button className="startButton" onClick={()=>setToggleState(false)}>End</button>}
            </div>
        </div>
    );
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyB0vsa7Ll6IH1zPClWcZAOaSt4DydOTLV4",
})(MapsNewComp);






// << --------------- Second Backup --------------------- >>


// import React, { useState, useEffect } from "react";
// import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
// import { DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
// import DummyData from './Dummy.json'
// import BikeSvgIc from "./assets/images/bike-new-ic.svg"
// import CatchmentSvgIc from "./assets/images/catchment-ic.svg"
// import GenexSvgIc from "./assets/images/genex-ic.svg"

// const mapStyles = {
//     width: "100%",
//     height: "100%",
// };
// console.log(DummyData, "::>>DummyData")
// function MapsNewComp(props) {
//     const [stores, setStores] = useState([
//         { latitude: 19.199004192441453, longitude: 72.95395528115708, name: "Ashar IT Park" },
//         { latitude: 19.208887106967016, longitude: 72.97169358115733, name: "Viviana Mall" },
//         { latitude: 19.188571537427347, longitude: 72.95495560814221, name: "Centrun Buisness park" },
//         { latitude: 19.225058, longitude: 72.948602, name: "Yeoor hills" },
//     ]);
//     const [directions, setDirections] = useState(null);
//     const [activeMarker, setActiveMarker] = useState(null);
//     const [showInfoWindow, setShowInfoWindow] = useState(false);
//     const [selectedPlace, setSelectedPlace] = useState({});
//     const [waypointsArray, setwaypointsArray] = useState([])
//     const [originPoint, setOriginPoint] = useState("")
//     const [destinationPoint, setDestinationPoint] = useState("")
    
//     const customIcons = [
//         BikeSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         CatchmentSvgIc,
//         GenexSvgIc,
//       ];

//     useEffect(() => {
//         // const waypoints = DummyData[0].map((store) => ({
//         //     location: { lat: store.location_latitude, lng: store.location_longitude },
//         // }));

//         let locArray = [];
//         const waypoints = DummyData[0].map((store) => {
//             locArray.push({ lat: store.location_latitude, lng: store.location_longitude }); 
//             setwaypointsArray(locArray);
//             return {
//                 location: { lat: store.location_latitude, lng: store.location_longitude },
//             };
//         });


//         const origin = waypoints.shift().location;
//         const destination = waypoints.pop().location;
//         setOriginPoint(origin);
//         setDestinationPoint(destination);

//         const directionsService = new window.google.maps.DirectionsService();
//         console.log(window.google.maps,"::>>google info")
//         console.log(directionsService,"::>>directionsService")
//         directionsService.route(
//             {
//                 origin: origin,
//                 destination: destination,
//                 waypoints: waypoints,
//                 travelMode: window.google.maps.TravelMode.DRIVING,
//             },
//             (result, status) => {
//                 if (status === window.google.maps.DirectionsStatus.OK) {
//                     setDirections(result);
//                 } else {
//                     console.error("Error fetching directions: ", result);
//                 }
//             }
//         );
//     }, [DummyData]);

//     const onMarkerClick = (props, marker) => {
//         setSelectedPlace(props);
//         setActiveMarker(marker);
//         setShowInfoWindow(true);
//     };

//     const displayMarkers = () => {
//         return DummyData[0].map((store, index) => {
//             const markerIcon = {
//                 url: customIcons[index],
//                 scaledSize: new window.google.maps.Size(40, 40),
//                 anchor: new window.google.maps.Point(17, 46),
//             };
//             return (
//                 <Marker
//                     key={index}
//                     position={{
//                         lat: store.location_latitude,
//                         lng: store.location_longitude,
//                     }}
//                     icon={markerIcon}
//                     onClick={(props, marker) =>
//                         onMarkerClick(
//                             {
//                                 name: store.location_name,
//                                 latitude: store.location_latitude,
//                                 longitude: store.location_longitude,
//                             },
//                             marker
//                         )
//                     }
//                 />
//             );
//         });
//     };

//     console.log(waypointsArray, "::>>waypointsArray")
//     console.log(originPoint, "::>>originPoint")

//     let waypointsTrimmed = waypointsArray.slice(1, -1);
//     let remainingWayPts = waypointsTrimmed.map(point => `${point.lat},${point.lng}`).join('|');

//     console.log(remainingWayPts,"::>>remainingWayPts");

//     let mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${originPoint.lat + ',' + originPoint.lng}&waypoints=${remainingWayPts}&destination=${destinationPoint.lat + ',' + destinationPoint.lng}`
//     return (
//         <div>
//             <Map
//                 google={props.google}
//                 zoom={20}
//                 style={mapStyles}
//                 initialCenter={{ lat: 19.199004192441453, lng: 72.95395528115708 }}
//             >
//                 {displayMarkers()}
//                 {directions && (
//                     <DirectionsRenderer
//                         options={{
//                             directions: directions,
//                         }}
//                     />
//                 )}
//                 <InfoWindow
//                     marker={activeMarker}
//                     visible={showInfoWindow}
//                     onClose={() => setShowInfoWindow(false)}
//                 >
//                     <div>
//                         <h4>{selectedPlace.name}</h4>
//                         <p>Latitude: {selectedPlace.latitude}</p>
//                         <p>Longitude: {selectedPlace.longitude}</p>
//                     </div>
//                 </InfoWindow>
//             </Map>
//             <button className="startButton"><a target="blank" href={mapUrl}>Start Trip</a></button>
//         </div>
//     );
// }

// export default GoogleApiWrapper({
//     apiKey: "AIzaSyB0vsa7Ll6IH1zPClWcZAOaSt4DydOTLV4",
// })(MapsNewComp);
