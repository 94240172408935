import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

export default function Labpagesecond() {
    const [value, setValue] = React.useState(1);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight labpagemain'>
       <div className='labpage'>
       <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
            Laboratory
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
     
       <div className='signinpageupperpart'>
       <Grid container spacing={2}>
      
       <Grid item xs={12} className='appnameright'>
       <div className='apptitleholder w-100'>
        <div className='headerapplogo'>
        </div>
       <p className='apptitle px-20px'>

Multi Disease Diagnostic Network Optimization </p>
       </div>
       </Grid>
       </Grid>
       
       <Grid container spacing={2}>
       <Grid item xs={12}>
        <p className='yellowlabelname mb-0 text-center'>
            Please complete the form.
        </p>
       </Grid>
       </Grid>
     
      
       </div>

       <div className='labpagelaterpart'>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Available diagnostic tests for Tuberculosis
        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Available diagnostic tests</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="Direct Sputum Smear Microscopy (DSSM)" />
      <FormControlLabel control={<Checkbox />} label="LED- Fluorescence Microscopy (LED-FM)" />
      <FormControlLabel control={<Checkbox />} label="GeneXpert (Xpert MTB/RIF)" />
      <FormControlLabel control={<Checkbox />} label="LAMP" />
      <FormControlLabel control={<Checkbox />} label="Solid Culture" />
      <FormControlLabel control={<Checkbox />} label="Liquid Culture (MGIT)" />
      <FormControlLabel control={<Checkbox />} label="First line solid DST" />
      <FormControlLabel control={<Checkbox />} label="Second line solid DST" />
      <FormControlLabel control={<Checkbox />} label="Line Probe Assay" />
      <FormControlLabel control={<Checkbox />} label="Others" />
    </FormGroup>
       </Grid>
       </Grid>

       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>If GeneXpert (Xpert MTB/RIF) then</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="2 placer" />
      <FormControlLabel control={<Checkbox />} label="4 placer" />
      <FormControlLabel control={<Checkbox />} label="16 placer" />
      <FormControlLabel control={<Checkbox />} label="Infinity (48 placer)" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Available diagnostic tests for HIV
        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Screening test</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="Antigen & antibody combo RDT" />
      <FormControlLabel control={<Checkbox />} label="Antibody RDT only" />
      <FormControlLabel control={<Checkbox />} label="ELISA" />
      <FormControlLabel control={<Checkbox />} label="Enzyme Immunoassays (EIAS) or Chemiluminescence immunoassays (CLIA)" />
      <FormControlLabel control={<Checkbox />} label="Other" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>If ELISA, specify</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>If Enzyme Immunoassays (EIAS) or Chemiluminescence immunoassays (CLIA), then 
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="Siemen ADVIA Centaur (drop down)
" />
      <FormControlLabel control={<Checkbox />} label="Abbot ARCHITECT system (Alinity I)  (drop down)
" />
      <FormControlLabel control={<Checkbox />} label="BioRad BioPlex 2200
" />
      <FormControlLabel control={<Checkbox />} label="Roche Cobas e602
" />
      <FormControlLabel control={<Checkbox />} label="Roche Cobas e801
" />
<FormControlLabel control={<Checkbox />} label="VITROS ECi/ECiQ, 3600, 5600, XT 7600

" />
<FormControlLabel control={<Checkbox />} label="Other
" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>If others, specify(Please specify brand of equipment:)
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Others- equipment based, specify

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Nucleic Acid Laboratory Test (Viral Load)

</p>
       </Grid>
      
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Identify equipment:</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="Roche Cobas 6800/8800
" />
      <FormControlLabel control={<Checkbox />} label="Hologic Panther
" />
      <FormControlLabel control={<Checkbox />} label="Abbott Alinity M

" />
      <FormControlLabel control={<Checkbox />} label="Abbott M- PIMA

" />
      <FormControlLabel control={<Checkbox />} label="Abbott M2000

" />
<FormControlLabel control={<Checkbox />} label="Roche COBAS AmpliPrep/COBAS TaqMan


" />
<FormControlLabel control={<Checkbox />} label="Roche Cobas 4800/5800



" />
<FormControlLabel control={<Checkbox />} label="Molbio Diagnostics Truenat



" />
<FormControlLabel control={<Checkbox />} label="GeneXpert


" />
<FormControlLabel control={<Checkbox />} label="Other
" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>If GeneXpert, then 
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="2 placer" />
      <FormControlLabel control={<Checkbox />} label="4 placer" />
      <FormControlLabel control={<Checkbox />} label="16 placer" />
      <FormControlLabel control={<Checkbox />} label="Infinity (48 placer)" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Specific Count of gene expert machines
        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Total number of 2 placer machines
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Total number of 4 placer machines
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Total number of 16 placer machines
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Total number of 48 placer(Infinity) machines.

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>CD4</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
      
      <FormGroup>
      <FormControlLabel control={<Checkbox />} label="CD4 RDT
" />
      <FormControlLabel control={<Checkbox />} label="Abbott PIMA
" />
  <FormControlLabel control={<Checkbox />} label="Beckman Coulter Aquios CL flow cytometer

" />
  <FormControlLabel control={<Checkbox />} label="BD FACSPrestor

" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Early Infant Diagnosis, please specify


</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Enumerate other laboratory instruments available for HIV tests:

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Maximum no. of tests run in GeneXpert per day per disease

        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Average no. of tests run in GeneXpert tests per day per disease


        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Average no. of tests run in GeneXpert tests per week per disease

        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>

       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Average Turn-Around-Time (TAT) of test per disease

        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>

       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Cost per test per disease charged to patient 



        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>


      
    
      
      
      
     
     
     
      
       
        
       
          
          
         
        
       
       
      
       
       
         

         
           
          
        
       {/* <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Password</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid> */}
       
      
     
        <p className='w-100'>
            <a href='#' className='greenbtn'>Submit</a>
        </p>
       </div>
      
      
       
       </div>
      </Container>
      <BottomNavigation
      className='btmnavbar'
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Facilities Info" icon={<DomainAddIcon />} />
        <BottomNavigationAction label="Diagnostic Info" icon={<MonitorHeartIcon />} />
        <BottomNavigationAction label="Samples Info" icon={<BloodtypeIcon />} />
      </BottomNavigation>
    </React.Fragment>
  );
}