import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';

export default function Homemenupage() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0 bg-green fullheight'>
       <div className='createprofilepage'>
       <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
           Home
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
       <div className='signinpageupperpart'>
       <Grid container spacing={2}>
      
       <Grid item xs={12} className='appnameright'>
       <div className='apptitleholder w-100'>
        <div className='headerapplogo'>
        </div>
     
       </div>
       </Grid>
       </Grid>
       
       <Grid container spacing={2} >
       <Grid item xs={12}>
        <p className='yellowlabelname mb-0 text-center'>
            MDNO
        </p>
       </Grid>
       </Grid>
     
      
       </div>
      
       <Grid container spacing={2} >
       <Grid item xs={12} className='pt-40px'>
        <div className='menuitemdiv'>
            <div className='mapmenudiv'>
            <Grid container spacing={2}>
       <Grid item xs={4} className='mt-minus40px'>
       <div className='mapimg'>

</div>
       </Grid>
       <Grid item xs={8} className='d-flex align-items-center'>
       <p className='menuname'>Rider</p>
       </Grid>
       </Grid>
               
             

            </div>

        </div>
       </Grid>
       <Grid item xs={12}>
        <div className='menuitemdiv '>
            <div className='mapmenudiv odd pl-20px pr-20px'>
            <Grid container spacing={2}>
      
       <Grid item xs={8} className='d-flex align-items-center '>
       <p className='menuname'>Labs</p>
       </Grid>
       <Grid item xs={4} className='mt-minus40px pr-40px'>
       <div className='labimg'>

</div>
       </Grid>
       </Grid>
               
             

            </div>

        </div>
       </Grid>
       <Grid item xs={12}>
        <div className='menuitemdiv'>
            <div className='mapmenudiv pl-20px pr-20px'>
            <Grid container spacing={2}>
       <Grid item xs={4} className='mt-minus50px'>
       <div className='catchmentimg'>

</div>
       </Grid>
       <Grid item xs={8} className='d-flex align-items-center'>
       <p className='menuname'>Catchment</p>
       </Grid>
       </Grid>
               
             

            </div>

        </div>
       </Grid>
       <Grid item xs={12}>
        <div className='menuitemdiv '>
            <div className='mapmenudiv odd  pl-20px pr-20px'>
            <Grid container spacing={2} className=''>
      
       <Grid item xs={8} className='d-flex align-items-center'>
       <p className='menuname'>Direct Courier</p>
       </Grid>
       <Grid item xs={4} className='mt-minus40px'>
       <div className='courierimg'>

</div>
       </Grid>
       </Grid>
               
             

            </div>

        </div>
       </Grid>
       </Grid>
      
       
       </div>
      </Container>
    </React.Fragment>
  );
}