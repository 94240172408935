import React from 'react'
import { useEffect } from 'react';
import { Circles } from 'react-loader-spinner';

export const getErrorString = (e, defaultErrorText = 'Something went wrong!') => {
  if (e && e.error && e.error.message && typeof e.error.message === 'string') {
    return e.error.message;
  };

  const errorString =
    e.response && e.response.data && e.response.data.message && typeof e.response.data.message === 'string'
      ? e.response.data.message
      : e.message && typeof e.message === 'string'
        ? e.message
        : typeof e === 'string'
          ? e
          : defaultErrorText;
  return errorString;
};

export const Loader = () => {
  return (
    <div className='loaderWrapper'>
      <div className='loaderInner'>
        <Circles
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </div>
  )
}
