import React from 'react'
import { Circles } from 'react-loader-spinner';

function PageLoader() {
    return (
        <div className='pageLoaderWrapper'>
            <div className='pageLoaderInner'>
                <Circles
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        </div>
    )
}

export default PageLoader