import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as URL from "../../services/url";
import { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/sidebar';

export default function Addsampledetails() {
  const navigate = useNavigate();
  let userRole = localStorage.getItem("userRole");
  let nonLabsDummyData = JSON.parse(localStorage.getItem("nonlabsData"));
  const [menuItem, setMenuItem] = useState('');
  const [defaultLatitude, setDefaultLatitude] = useState("");
  const [defaultLongitude, setDefaultLongitude] = useState("");
  const [selectedMenuItem, setSelectedMenuItem] = useState("")
  const [hivCount, setHIVCount] = useState("")
  const [tbCount, setTBCount] = useState("")
  const [hpvCount, setHPVCount] = useState("")
  const [loading, setLoading] = useState(false);

  console.log(localStorage.getItem("nonLabsData"), "::>>nonLabsData")
  console.log(nonLabsDummyData, "::>>nonLabsDummyData")
  const handleChange = (event) => {
    // console.log(event, "::>>event")
    const selectedValues = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
    setSelectedMenuItem(selectedValues);
  };
  console.log(selectedMenuItem, "::>>selectedMenuItem")

  useEffect(() => {
    getNonLabListData();
  }, [])


  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, error);
  } else {
    console.log("Geolocation not supported");
  }

  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setDefaultLatitude(latitude);
    setDefaultLongitude(longitude)
    console.log(`Latitude: ${latitude}, Longitude: ${longitude} register`);
  }

  function error() {
    console.log("Unable to retrieve your location");
  }

  const getNonLabListData = async () => {
    let reqBody = {
      "userlat":14.660449,
      "userlong":120.94646
    }
    setLoading(true)
    try {
      const response = await axios.post(URL.getNonLabList, reqBody);
      setLoading(false)
      if (response.status == 200) {
        console.log(response, "::>>getNonLabListData")
        setMenuItem(response.data.locations)
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error)
      console.error('Error:', error);
    }
  }

  console.log(hivCount, tbCount, hpvCount, "::>>Count")

  const handleAddSample = async () => {
    let reqBody = {
      faccode: selectedMenuItem[0]?.facility_code,
      facname: selectedMenuItem[0]?.facility_name,
      factype: "non-genx",
      tbsample: tbCount ? tbCount : 0,
      hivsample: hivCount ? hivCount : 0,
      hpvsample: hpvCount ? hpvCount : 0
    }
    setLoading(true)
    try {
      console.log(reqBody, "::>>reqBody")
      const response = await axios.post(URL.addSample, reqBody);
      setLoading(false)
      if (response.status == 200) {
        navigate("/riderdeliveries")
        setHIVCount("");
        setTBCount("");
        setHPVCount("");
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error)
      console.error('Error:', error);
    }
  }

  const handleNonLabsAddSample = async () => {
    let reqBody = {
      faccode: "DOH000000000007165",
      facname: nonLabsDummyData?.facilityName,
      factype: "non-genx",
      tbsample: tbCount ? tbCount : 0,
      hivsample: hivCount ? hivCount : 0,
      hpvsample: hpvCount ? hpvCount : 0
    }

    try {
      console.log(reqBody, "::>>reqBody")
      const response = await axios.post(URL.addSample, reqBody);
      if (response.status == 200) {
        toast.success("Sample added successfully")
        setHIVCount("");
        setTBCount("");
        setHPVCount("");
      }
    } catch (error) {
      toast.error(error)
      console.error('Error:', error);
    }
  }



  // console.log(defaultLatitude, defaultLongitude, "::>>Default Location")
  console.log(menuItem, ":::menuItem")
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight addbankdetailspagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar></Sidebar>
                {/* <MenuIcon /> */}
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Add Sample Details
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>
                    Multi Disease Diagnostic Network Optimization
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please add sample details.
                </p>
              </Grid>
            </Grid>


          </div>
          <div className='createprofilepagelaterpart'>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Source</p>
              </Grid>
              {userRole == "strider" &&
                <Grid item xs={12} className='pt-0px selectsourcediv'>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedMenuItem && selectedMenuItem[0]}
                    label="menuItem"
                    onChange={handleChange}
                  >
                    {Array.isArray(menuItem) && menuItem.map((data, index) => {
                      console.log(data, "::>>TRIM")
                      return (
                        <MenuItem value={data}>{data?.facility_name}</MenuItem>
                      )
                    })}
                  </Select>

                </Grid>}
              {userRole == "nonlabs" &&
                <Grid item xs={12} className='pt-0px selectsourcediv'>
                  <TextField id="outlined-basic"
                    name="facilityName"
                    label=""
                    variant="outlined"
                    placeholder=''
                    value={nonLabsDummyData?.facilityName}
                    disabled
                  />
                </Grid>}
            </Grid>
            <Grid container spacing={2} className='formrow'>
              <Grid item xs={12}>
                <p className='m-0 text-left formlabelbold'>Sample Details</p>
              </Grid>
              <Grid item xs={12} className='pt-0px'>
                <FormGroup>
                  <div className='sampledetailcheckmain'>
                    <FormControlLabel control={<Checkbox />} label="HIV" />
                    <TextField id="outlined-basic" label="" onChange={(e) => setHIVCount(e.target.value)} value={hivCount} variant="outlined" placeholder='' className='samplenumbertextbox' />
                  </div>
                  <div className='sampledetailcheckmain'>
                    <FormControlLabel control={<Checkbox />} label="TB" />
                    <TextField id="outlined-basic" label="" onChange={(e) => setTBCount(e.target.value)} value={tbCount} variant="outlined" placeholder='' className='samplenumbertextbox' />
                  </div>
                  <div className='sampledetailcheckmain'>
                    <FormControlLabel control={<Checkbox />} label="HPV" />
                    <TextField id="outlined-basic" label="" onChange={(e) => setHPVCount(e.target.value)} value={hpvCount} variant="outlined" placeholder='' className='samplenumbertextbox' />
                  </div>
                  {/* <div className='sampledetailcheckmain'>
                    <FormControlLabel control={<Checkbox />} label="COVID" />
                    <TextField id="outlined-basic" label="" variant="outlined" placeholder='' className='samplenumbertextbox' />
                  </div> */}
                </FormGroup>
              </Grid>
            </Grid>

            <p className='w-100'>
              {userRole == "strider" && <button onClick={handleAddSample} className='greenbtn'>Add</button>}
              {userRole == "nonlabs" && <button onClick={handleNonLabsAddSample} className='greenbtn'>Add</button>}
            </p>
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}