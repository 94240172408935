import { useEffect, useState } from 'react';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import Sidebar from './../../components/Sidebar/sidebar';
import { Popup, Marker } from 'react-leaflet'
import { MapContainer } from 'react-leaflet/MapContainer'
import { TileLayer } from 'react-leaflet/TileLayer'
import { useMap } from 'react-leaflet/hooks'
import L from "leaflet"
import riderIc from '../../assets/images/delivery.png'
import PageLoader from '../../components/PageLoader/PageLoader';
export default function Riderlogin() {
  const navigate = useNavigate();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleStartRide = () => {
    navigate("/riderdeliveries")
  }

  useEffect(() => {
    const updateCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition([latitude, longitude]);
          setLoading(false)
        });
      } else {
        console.log('Geolocation not supported');
      }
    };

    const intervalId = setInterval(updateCurrentLocation, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  let defaultIcon = L.icon({
    iconUrl: riderIc,
    iconSize: [30, 30],
    // iconAnchor: [12, 41],
    // popupAnchor: [1, -34],
  });

  L.Marker.prototype.options.icon = defaultIcon;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight riderloginpage'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <Sidebar></Sidebar>
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                STRider
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>


          {/* <div className='riderloginpagepart'> */}
          <div className='riderloginpageMappart'>
            {/* {currentPosition && (
              <div>
                <MapContainer center={currentPosition} zoom={13} scrollWheelZoom={false} >
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={currentPosition}>
                    <Popup>
                      Your current location. <br /> Latitude: {currentPosition[0]}, Longitude: {currentPosition[1]}
                    </Popup>
                  </Marker>
                </MapContainer>
                <Grid container spacing={2} className='formrow w-100'>
                  <Grid item xs={12} className=''>
                    <p className='text-center'>
                    </p>
                  </Grid>
                  <Grid item xs={12} className='riderstartbtnholder'>
                    <p className='text-center'>
                      <button className='riderstartbtn' onClick={handleStartRide}>Start</button>
                    </p>
                  </Grid>
                </Grid>
              </div>
            )} */}

            {loading ? (
              <PageLoader />
            ) : (
              <div>
                <MapContainer center={currentPosition} zoom={13} scrollWheelZoom={false} >
                  <TileLayer
                    attribution='&copy;'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={currentPosition}>
                    <Popup>
                      Your current location. <br /> Latitude: {currentPosition[0]}, Longitude: {currentPosition[1]}
                    </Popup>
                  </Marker>
                </MapContainer>
                <Grid container spacing={2} className='formrow w-100'>
                  <Grid item xs={12} className=''>
                    <p className='text-center'>
                      {/* <div className='ridericon'></div> */}
                    </p>
                  </Grid>
                  <Grid item xs={12} className='riderstartbtnholder'>
                    <p className='text-center'>
                      <button className='riderstartbtn' onClick={handleStartRide}>Start</button>
                    </p>
                  </Grid>
                </Grid>
              </div>
            )
            }
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
}