import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import BloodtypeIcon from '@mui/icons-material/Bloodtype';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

export default function Labpagethird() {
    const [value, setValue] = React.useState(2);
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight labpagemain'>
       <div className='labpage'>
       <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
            Laboratory
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
     
       <div className='signinpageupperpart'>
       <Grid container spacing={2}>
      
       <Grid item xs={12} className='appnameright'>
       <div className='apptitleholder w-100'>
        <div className='headerapplogo'>
        </div>
       <p className='apptitle px-20px'>

Multi Disease Diagnostic Network Optimization </p>
       </div>
       </Grid>
       </Grid>
       
       <Grid container spacing={2}>
       <Grid item xs={12}>
        <p className='yellowlabelname mb-0 text-center'>
            Please complete the form.
        </p>
       </Grid>
       </Grid>
     
      
       </div>

       <div className='labpagelaterpart'>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Other Specific Details

        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>How patient samples are referred to your laboratory?
</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <FormGroup>
      <FormControlLabel control={<Checkbox />} label="Directly from clinic/ward to the laboratory within the same building or compound
" />
      <FormControlLabel control={<Checkbox />} label="Striders/ motorbikes
" />
      <FormControlLabel control={<Checkbox />} label="GPooled samples via PHO/ CHO
" />
      <FormControlLabel control={<Checkbox />} label="Private courier
" />
      <FormControlLabel control={<Checkbox />} label="Personal or via family members
" />
      <FormControlLabel control={<Checkbox />} label="Request from physician affiliated to other institutions (primary care facilities, private clinics, etc.)
" />
     
      <FormControlLabel control={<Checkbox />} label="Others" />
    </FormGroup>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
        Name of facilities currently referring patient samples to the laboratory per disease



        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>TB

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HIV

</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>HPV

</p>
       </Grid>
       
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>

       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
        <p className='diseasetitle'>
Estimated Travel Time


        </p>
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>What is the longest estimated travel time of farthest referring facility to your laboratory? (HH:MM)


</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
       <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>What is the shortest estimated travel time of nearest referring facility to your laboratory? (HH:MM)


</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid>
      


       
       {/* <Grid container spacing={2} className='formrow'>
       <Grid item xs={12}>
       <p className='m-0 text-left formlabelbold'>Password</p>
       </Grid>
       <Grid item xs={12} className='pt-0px'>
       <TextField id="outlined-basic" label="" variant="outlined" placeholder='' />
       </Grid>
       </Grid> */}
       
      
     
        <p className='w-100'>
            <a href='#' className='greenbtn'>Submit</a>
        </p>
       </div>
      
      
       
       </div>
      </Container>
      <BottomNavigation
      className='btmnavbar'
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Facilities Info" icon={<DomainAddIcon />} />
        <BottomNavigationAction label="Diagnostic Info" icon={<MonitorHeartIcon />} />
        <BottomNavigationAction label="Samples Info" icon={<BloodtypeIcon />} />
      </BottomNavigation>
    </React.Fragment>
  );
}