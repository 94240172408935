import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { async } from 'q';
import * as URL from "../../services/url";
import { getErrorString } from '../../constants/utils';
import axios from 'axios';
import PageLoader from '../../components/PageLoader/PageLoader';
import Sidebar from '../../components/Sidebar/sidebar';


export default function Uploaddoc() {
  const navigate = useNavigate();
  let userRole = localStorage.getItem("userRole");
  const [birthCertificateURL, setBirthCertificateURL] = useState("")
  const [drivingLicenseURL, setDrivingLicenseURL] = useState("")
  const [passportURL, setPassportURL] = useState("")
  const [loading, setLoading] = useState(false);
  const [birthCertificateFileName, setBirthCertificateFileName] = useState("");
  const [drivingLicenseFileName, setDrivingLicenseFileName] = useState("");
  const [passportFileName, setPassportFileName] = useState("");
  const [fileUrl, setFileUrl] = useState("")

 
  const handleNextPage = () => {
    if (birthCertificateFileName && drivingLicenseFileName && passportFileName) {
      navigate("/uploadbikedoc")
    }
    else {
      toast.error("Please upload all the documents")
    }
  }

  const handleUploadFile = async (e, param) => {
    const file = e.target.files[0];
    if(file && file.size > 5000000){
      toast.error("Document size should be less than 5 MB");
      return;
    }
    console.log(file,">>file")
    let fileUrl;
    if (file instanceof Blob) {
      if (param === "BirthCertificate") {
        // setBirthCertificateFileName(file.name);
        let fileType = file.type.split('/').pop();
        setBirthCertificateFileName(`birth-certificate.${fileType}`);
      } else if (param === "DrivingLicense") {
        // setDrivingLicenseFileName(file.name);
        let fileType = file.type.split('/').pop();
        setDrivingLicenseFileName(`driving-license.${fileType}`);
      } else if (param === "Passport") {
        // setPassportFileName(file.name);
        let fileType = file.type.split('/').pop();
        setPassportFileName(`passport.${fileType}`);
      }

      const reader = new FileReader();
      const fileUrlPromise = new Promise((resolve, reject) => {
        reader.onload = (e) => {
          const url = e.target.result;
          fileUrl = url;
          resolve(url);
        };
        reader.onerror = (error) => {
          reader.abort();
          reject(error);
        };
      });

      reader.readAsDataURL(file);
      await fileUrlPromise;
    } else {
      toast.error("Invalid file format");
    }

    let docType = "";
    if (param == "BirthCertificate") {
      docType = "birthcert";
    } else if (param == "DrivingLicense") {
      docType = "drivelic";
    } else if (param == "Passport") {
      docType = "passport";
    }

    const body = {
      phonenumber:
        localStorage.getItem("countryCode") +
        localStorage.getItem("phoneNumber"),
      doctype: docType,
      docbody: fileUrl,
    };

    setLoading(true);
    try {
      const response = await axios.post(URL.uploadDocuments, body);
      setLoading(false);
      if (response.status === 200) {
        // navigate("/uploadbikedoc");
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    userRole && getAllDocuments()
  }, [])

  const handleCheckExtension = (docUrl, param) => {
    let extension;
    if (docUrl && docUrl.includes("image/jpeg")) {
      extension = "jpeg";
      param == "birthcert" && setBirthCertificateFileName(`birth-certificate.${extension}`)
      param == "drivelic" && setDrivingLicenseFileName(`driving-license.${extension}`)
      param == "passport" && setPassportFileName(`passport.${extension}`)
    } else if (docUrl && docUrl.includes("image/png")) {
      extension = "png";
      param == "birthcert" && setBirthCertificateFileName(`birth-certificate.${extension}`)
      param == "drivelic" && setDrivingLicenseFileName(`driving-license.${extension}`)
      param == "passport" && setPassportFileName(`passport.${extension}`)
    } else if (docUrl && docUrl.includes("application/pdf")) {
      extension = "pdf";
      param == "birthcert" && setBirthCertificateFileName(`birth-certificate.${extension}`)
      param == "drivelic" && setDrivingLicenseFileName(`driving-license.${extension}`)
      param == "passport" && setPassportFileName(`passport.${extension}`)
    } else {
      extension = "unknown";
    }
  }

  const getAllDocuments = async () => {
    const body = {
      phonenumber: localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"),
      type: "documents",
      // docbody: fileUrl,
    };

    setLoading(true);
    try {
      const response = await axios.post(URL.getAlluploadedDocuments, body);
      setLoading(false);
      if (response.status === 200) {
        console.log(response, ":>>responsee")
        handleCheckExtension(response.data.data.birthcert, "birthcert")
        handleCheckExtension(response.data.data.drivelic, "drivelic")
        handleCheckExtension(response.data.data.passport, "passport")
      }
    } catch (error) {
      toast.error(getErrorString(error));
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }






  return (
    <React.Fragment>
      {loading && <PageLoader />}
      <CssBaseline />
      <Container maxWidth="lg" className='p-0  fullheight uploaddocpagemain'>
        <div className='createprofilepage'>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                {/* <MenuIcon /> */}
                {userRole && <Sidebar></Sidebar>}
              </IconButton>
              <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                Upload Personal Document
              </Typography>
              {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
          </AppBar>
          <div className='signinpageupperpart'>
            <Grid container spacing={2}>

              <Grid item xs={12} className='appnameright'>
                <div className='apptitleholder w-100'>
                  <div className='headerapplogo'>
                  </div>
                  <p className='apptitle px-20px'>
                    Multi Disease Diagnostic Network Optimization
                  </p>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p className='yellowlabelname mb-0 text-center'>
                  Please upload documents
                </p>
              </Grid>
            </Grid>
          </div>

          <div className='createprofilepagelaterpart'>
            <div className='uploaddocformholder'>
              <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Birth Certificate
                    <span className="fileNamelabel">{birthCertificateFileName ? `File : ${birthCertificateFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadFile(e, "BirthCertificate")}
                    />
                  </div>
                  {/* <a href='#' className='greenbtn'>Upload</a> */}
                </Grid>
              </Grid>
              <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Driving License
                    <span className="fileNamelabel">{drivingLicenseFileName ? `File : ${drivingLicenseFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadFile(e, "DrivingLicense")}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className='formrow pt-30px'>
                <Grid item xs={8}>
                  <p className='m-0 text-left formlabelbold'>Passport
                    <span className="fileNamelabel">{passportFileName ? `File : ${passportFileName}` : ""}</span>
                  </p>
                </Grid>
                <Grid item xs={4} className='pt-10px'>
                  <div className='uploadBtn'>Upload
                    <input
                      type='file'
                      className='greenbtn'
                      onChange={(e) => handleUploadFile(e, "Passport")}
                    />
                  </div>
                </Grid>
              </Grid>
              <p className='w-100 mt-40px'>
                <button className='greenbtn' onClick={handleNextPage}>Next</button>
              </p>
            </div>
          </div>



        </div>
      </Container>
    </React.Fragment>
  );
}