import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BikeSvgIc from "../../assets/images/bike-new-ic.svg"
import { Loader } from '../../constants/utils';
import PageLoader from '../../components/PageLoader/PageLoader';

function InititalRiderMap(props) {
  const {setLoading, loading} = props;
  const [currentPosition, setCurrentPosition] = useState(null);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition([latitude, longitude]);
          setLoading(false); 
        });
      } else {
        console.log('Geolocation not supported');
      }
    };

    const intervalId = setInterval(updateCurrentLocation, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <MapContainer center={currentPosition} zoom={13} scrollWheelZoom={true} style={{ height: '500px', width: '100%' }}>
          <TileLayer
            attribution='&copy;'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {currentPosition && (
            <Marker position={currentPosition}>
              <Popup>
                Your current location. <br /> Latitude: {currentPosition[0]}, Longitude: {currentPosition[1]}
              </Popup>
            </Marker>
          )}
        </MapContainer>
      )}
    </div>
  );
}

export default InititalRiderMap;
