import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Formio } from 'formiojs';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as URL from "../../services/url";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getErrorString } from '../../constants/utils';
import PageLoader from '../../components/PageLoader/PageLoader';
import Sidebar from '../../components/Sidebar/sidebar';

export default function RiderProfilePage() {
    const [currentPage, setCurrentPage] = useState(0);
    const [striderFormData, setStriderFormData] = useState("")
    const [defaultLatitude, setDefaultLatitude] = useState("")
    const [defaultLongitude, setDefaultLongitude] = useState("")
    const [loading, setLoading] = useState(false);
    const [editForm, setEditForm] = useState(true)
    const navigate = useNavigate();
    let userRole = localStorage.getItem("userRole");
    const [striderProfileData, setStriderProfileData] = useState("");
    const [toggle, settoggle] = useState(false)
    console.log(editForm, "::>>editForm")
    const handleEditForm = () => {
        setEditForm(!editForm)
    }

    useEffect(() => {
        getAllDocuments()
    }, [])

    const getAllDocuments = async () => {
        const body = {
            phonenumber: localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"),
            type: "profile",
        };

        setLoading(true);
        try {
            const response = await axios.post(URL.getAlluploadedDocuments, body);
            setLoading(false);
            if (response.status === 200) {
                setStriderProfileData(response.data.data)
            }
        } catch (error) {
            toast.error(getErrorString(error));
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        Formio.createForm(document.getElementById('formio'), {
            display: "wizard",
            components: [
                {
                    "title": "Page 1",
                    "breadcrumb": "none",
                    "breadcrumbClickable": true,
                    "buttonSettings": {
                        "previous": false,
                        "cancel": false,
                        "next": true
                    },
                    "navigateOnEnter": false,
                    "saveOnEnter": false,
                    "scrollToTop": false,
                    "collapsible": false,
                    "hideLabel": true,
                    "key": "page1",
                    "type": "panel",
                    "label": "Page 1",
                    "components": [
                        {
                            "label": "First Name",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter first name",
                            "key": "firstname",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData && striderProfileData.fname ? striderProfileData.fname : "",
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Last Name",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter last name",
                            "key": "lastname",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.lname,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Address",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter address",
                            "key": "address",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.address,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Bank Name",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter bank name",
                            "key": "bankname",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.bankname,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Account Holder Name",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter acc. holder name",
                            "key": "accholdername",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.accholdername,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Account Number",
                            "applyMaskOn": "change",
                            "mask": false,
                            "tableView": false,
                            "delimiter": false,
                            "requireDecimal": false,
                            "inputFormat": "plain",
                            "truncateMultipleSpaces": false,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter acc. number",
                            "key": "accountnumber",
                            "type": "number",
                            "input": true,
                            defaultValue: striderProfileData?.accnumber,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "IFSC Code",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter ifsc code",
                            "key": "ifsc",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.ifsc,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Brand",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter brand",
                            "key": "brand",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.brand,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Model",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter model",
                            "key": "model",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.model,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Number Plate",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter number plate",
                            "key": "numplate",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.numberplate,
                            "disabled": editForm == true ? true : false,
                        },
                        {
                            "label": "Color",
                            "applyMaskOn": "change",
                            "tableView": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Please enter color",
                            "key": "color",
                            "type": "textfield",
                            "input": true,
                            defaultValue: striderProfileData?.color,
                            "disabled": editForm == true ? true : false,
                        }
                    ],
                    "input": false,
                    "tableView": false
                },

            ]
        }).then(function (wizard) {

            wizard.on('submit', function (submission) {
                console.log(submission, "::>>submission")
                // setStriderFormData(submission?.data)
                handleSubmitStriderProfile(submission?.data, defaultLatitude, defaultLongitude)
            })
        })

    }, [striderProfileData, editForm])


    let breadcrumbHeading = [
        "Create Profile",
        "Add Bank Details",
        "Add Vehicle Details",
    ]


    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error);
    } else {
        console.log("Geolocation not supported");
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setDefaultLatitude(latitude);
        setDefaultLongitude(longitude)
    }

    function error() {
        console.log("Unable to retrieve your location");
    }

    const handleSubmitStriderProfile = async (data, latitude, longitude) => {
        let formData = new FormData();
        formData.append('fname', data?.firstname);
        formData.append('lname', data?.lastname);
        formData.append('address', data?.address);
        formData.append('bankname', data?.bankname);
        formData.append('accholdername', data?.accholdername);
        formData.append('accnumber', data?.accountnumber);
        formData.append('ifsc', data?.ifsc);
        formData.append('brand', data?.brand);
        formData.append('model', data?.model);
        formData.append('numberplate', data?.numplate);
        formData.append('color', data?.color);
        formData.append('latitude', latitude);
        formData.append('longitude', longitude);
        formData.append('phonenumber', localStorage.getItem("countryCode") + localStorage.getItem("phoneNumber"));
        setLoading(true)
        try {
            const response = await axios.post(URL.registerStrider, formData);
            setLoading(false)
            if (response.status == 200) {
                // navigate("/thankyou");
                // navigate("/uploaddoc")
                setEditForm(true)
                getAllDocuments()
                toast.success(response.message)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            toast.error(getErrorString(error))
        } finally {
            setLoading(false)
        }

    }

    return (
        <React.Fragment>
            {loading && <PageLoader />}
            <CssBaseline />
            <Container maxWidth="lg" className='p-0  fullheight createprofilepagemain'>
                <div className='createprofilepage'>
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                {/* <MenuIcon /> */}
                                {userRole && <Sidebar></Sidebar>}
                            </IconButton>
                            <Typography variant="h6" component="div" className='appheadertitle' sx={{ flexGrow: 1 }}>
                                {breadcrumbHeading[currentPage]}
                            </Typography>
                            {/* <Button color="inherit">Login</Button> */}
                        </Toolbar>
                    </AppBar>
                    <div className='signinpageupperpart'>
                        <Grid container spacing={2}>

                            <Grid item xs={12} className='appnameright'>
                                <div className='apptitleholder w-100'>
                                    <div className='headerapplogo'>
                                    </div>
                                    <p className='apptitle px-20px'>

                                        Multi Disease Diagnostic Network Optimization </p>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className='yellowlabelname mb-0 text-center'>
                                    Please complete the form.
                                </p>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='createprofilepagelaterpart'>
                        <div id="formio"></div>
                        <div className='editFormWrapper'>
                            <button className='greenbtn' onClick={handleEditForm}>
                                Edit Form
                            </button>
                        </div>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
}